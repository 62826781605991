import { Button } from '@mui/material';
import theme from '../../../theme';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import CameraSVG from '../../../assets/icons/details/camera.svg';
import { onWebAppCostEstimationSkipPicture } from '../../../tracking/trackers';
import { BodyBold } from '../../../components/Text';
import { useCostEstimator } from './CostEstimatorProvider';

const TakePhoto = ({
    imageInputRef,
    source,
    title,
    description,
    disableSkip,
    buttonText,
}: any) => {
    const { width = 768 } = useWindowDimensions();
    const { setStep } = useCostEstimator();

    const circleDimension = width ? Math.min(width * 0.5, 250) : 250;

    return (
        <div
            style={{
                padding: '0 20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
            }}
        >
            <div
                style={{
                    textAlign: 'center',
                    width: '100%',
                }}
            >
                <BodyBold style={{ fontSize: width < 768 ? 22 : 30 }}>
                    {source !== 'dashboard'
                        ? 'Construction Project Cost Estimator'
                        : ''}
                </BodyBold>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <div
                    style={{
                        width: circleDimension,
                        height: circleDimension,
                        backgroundColor: theme.colors.offWhite,
                        borderRadius: 1000,
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        margin: '50px 0',
                    }}
                >
                    <img
                        src={CameraSVG}
                        width={150}
                        height={150}
                        alt="camera"
                    />
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    width: '80%',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        color: theme.colors.grayText,
                        width: '90%',
                        fontSize: 16,
                        textAlign: 'center',
                        lineHeight: 1.5,
                    }}
                >
                    {description ||
                        'Take a photo of what you want to repair or modify (e.g. “paint this wall”)'}
                </div>
                <Button
                    variant="contained"
                    color="secondary"
                    style={{
                        color: 'white',
                        boxShadow: 'none',
                        borderRadius: 25,
                        height: 50,
                        width: '60%',
                        fontWeight: '700',
                        fontSize: 20,
                        marginTop: 30,
                        minWidth: 350,
                    }}
                    onClick={() => {
                        imageInputRef.current?.click();
                    }}
                >
                    {buttonText || 'Take a Photo'}
                </Button>
                {!disableSkip ? (
                    <Button
                        variant="outlined"
                        color="secondary"
                        style={{
                            color: theme.colors.primary400,
                            boxShadow: 'none',
                            borderRadius: 25,
                            height: 50,
                            width: '60%',
                            fontWeight: '700',
                            fontSize: 20,
                            marginTop: 20,
                            borderWidth: 1,
                            borderColor: theme.colors.primary400,
                            minWidth: 350,
                        }}
                        onClick={() => {
                            onWebAppCostEstimationSkipPicture({
                                image_id: 'noImage',
                            });
                            setStep('1');
                        }}
                    >
                        Skip
                    </Button>
                ) : null}
            </div>
        </div>
    );
};

export default TakePhoto;
