import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const Container = styled('div')({
    padding: '0 1.25rem',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '37.5rem',
    margin: '0 auto',
    height: '100%',
    position: 'relative',
});

export const TitleContainer = styled('div')({
    marginBottom: '1rem',
    marginTop: '2rem',
    textAlign: 'center',
});

export const StyledTextArea = styled('textarea')(({ theme }) => ({
    'width': '100%',
    'height': '70%',
    'padding': '1rem',
    'borderRadius': '0.75rem',
    'border': '1px solid #D1D2D9',
    'fontSize': '1rem',
    'lineHeight': '1.5',
    'resize': 'none',
    'fontFamily': 'inherit',
    '&:focus': {
        outline: 'none',
    },
    [theme.breakpoints.up('md')]: {
        width: '120%',
        height: '50%',
        marginLeft: '-10%',
    },
}));

export const ButtonsContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '80%',
    alignItems: 'center',
    margin: '0 auto',
});

export const ContinueButton = styled(Button)({
    color: 'white',
    boxShadow: 'none',
    borderRadius: 25,
    height: 50,
    width: '100%',
    fontWeight: '700',
    fontSize: 20,
    marginTop: '1.5rem',
    minWidth: 300,
    textTransform: 'none',
});
