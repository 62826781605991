import React, { useState, useRef } from 'react';
import { Button, Tooltip } from '@mui/material';
import { useQuestionaire } from '../QuestionaireProvider';
import { BodyBold } from '../../../../components/Text';
import { onWebAppCostEstimationBookingLinkStep } from '../../../../tracking/trackers';
import {
    Container,
    TitleContainer,
    FormContainer,
    InputGroup,
    StyledLabel,
    StyledInput,
    RequiredIndicator,
    ButtonContainer,
} from './styles';

const StepClientInfo = () => {
    const { setStep, submitProgressToForm, formId, projectName } =
        useQuestionaire();
    const [formData, setFormData] = useState({
        name: '',
        address: '',
        mobile: '',
        email: '',
    });
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipTimer = useRef<NodeJS.Timeout>();

    const isFormValid =
        formData.name.trim() && formData.email.trim() && formData.mobile.trim();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        await submitProgressToForm({
            client_info: {
                name: formData.name,
                address: formData.address,
                mobile: formData.mobile,
                email: formData.email,
            },
            project_name: projectName,
        });
        onWebAppCostEstimationBookingLinkStep({
            step: 'client-info',
            form_id: formId || 0,
            metadata: {
                client_info: formData,
            },
        });
        setStep('step-done');
    };

    const showNumberTooltip = () => {
        setShowTooltip(true);
        if (tooltipTimer.current) {
            clearTimeout(tooltipTimer.current);
        }
        tooltipTimer.current = setTimeout(() => {
            setShowTooltip(false);
        }, 2000);
    };

    return (
        <Container>
            <TitleContainer>
                <BodyBold style={{ fontSize: 24 }}>Your Info</BodyBold>
            </TitleContainer>

            <FormContainer onSubmit={handleSubmit}>
                <InputGroup>
                    <StyledLabel>
                        Your Name
                        <RequiredIndicator>*</RequiredIndicator>
                    </StyledLabel>
                    <StyledInput
                        type="text"
                        value={formData.name}
                        onChange={(e) =>
                            setFormData({ ...formData, name: e.target.value })
                        }
                        placeholder="Enter your name"
                        required
                    />
                </InputGroup>
                <InputGroup>
                    <StyledLabel>Your Address</StyledLabel>
                    <StyledInput
                        type="text"
                        value={formData.address}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                address: e.target.value,
                            })
                        }
                        placeholder="Enter address"
                    />
                </InputGroup>
                <InputGroup>
                    <StyledLabel>
                        Mobile
                        <RequiredIndicator>*</RequiredIndicator>
                    </StyledLabel>
                    <Tooltip
                        open={showTooltip}
                        title="Please enter numbers only"
                        placement="top"
                        arrow
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    'bgcolor': 'rgba(0, 0, 0, 0.8)',
                                    '& .MuiTooltip-arrow': {
                                        color: 'rgba(0, 0, 0, 0.8)',
                                    },
                                    'borderRadius': '8px',
                                    'padding': '8px 16px',
                                    'fontSize': '14px',
                                    'fontWeight': 500,
                                    'boxShadow': '0 4px 6px rgba(0, 0, 0, 0.1)',
                                },
                            },
                        }}
                    >
                        <StyledInput
                            type="number"
                            value={formData.mobile}
                            onChange={(e) => {
                                const value = e.target.value.replace(/\D/g, '');
                                setFormData({ ...formData, mobile: value });
                            }}
                            onKeyDown={(e) => {
                                if (
                                    !/[0-9]/.test(e.key) &&
                                    e.key !== 'Backspace' &&
                                    e.key !== 'Delete' &&
                                    e.key !== 'ArrowLeft' &&
                                    e.key !== 'ArrowRight'
                                ) {
                                    e.preventDefault();
                                    showNumberTooltip();
                                }
                            }}
                            placeholder="Enter mobile number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            required
                        />
                    </Tooltip>
                </InputGroup>
                <InputGroup>
                    <StyledLabel>
                        Email
                        <RequiredIndicator>*</RequiredIndicator>
                    </StyledLabel>
                    <StyledInput
                        type="email"
                        value={formData.email}
                        onChange={(e) =>
                            setFormData({ ...formData, email: e.target.value })
                        }
                        placeholder="Enter email"
                        required
                    />
                </InputGroup>
                <ButtonContainer>
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{
                            color: 'white',
                            boxShadow: 'none',
                            borderRadius: 25,
                            height: 50,
                            width: '80%',
                            fontWeight: '700',
                            fontSize: 20,
                            minWidth: 350,
                            textTransform: 'none',
                        }}
                        type="submit"
                        disabled={!isFormValid}
                    >
                        Continue
                    </Button>
                </ButtonContainer>
            </FormContainer>
        </Container>
    );
};

export default StepClientInfo;
