import React from 'react';
import alldone from '../../../../assets/alldone.svg';
import { Container, SignImage, MessageText } from './styles';

const StepDone = () => {
    return (
        <Container>
            <SignImage>
                <img src={alldone} alt="All Done!" />
            </SignImage>
            <MessageText>We'll be in touch shortly!</MessageText>
        </Container>
    );
};

export default StepDone;
