import { useQuestionaire } from '../QuestionaireProvider';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import CameraSVG from '../../../../assets/icons/details/camera.svg';
import { BodyBold } from '../../../../components/Text';
import { onWebAppCostEstimationBookingLinkSkipTakePhoto } from '../../../../tracking/trackers';
import {
    Container,
    ImageContainer,
    CircleContainer,
    TitleContainer,
    ButtonsContainer,
    Description,
    TakePhotoButton,
    SkipButton,
} from './styles';

const StepTakePhoto = ({
    imageInputRef,
}: {
    imageInputRef: React.RefObject<HTMLInputElement>;
}) => {
    const { width = 768 } = useWindowDimensions();
    const { setStep, setImageIds, submitProgressToForm, formId } =
        useQuestionaire();
    const circleDimension = width ? Math.min(width * 0.5, 250) : 250;

    const handleSkip = async () => {
        setImageIds([]);
        await submitProgressToForm({
            image_ids: [],
        });
        onWebAppCostEstimationBookingLinkSkipTakePhoto({
            form_id: formId || 0,
        });
        setStep('project-description');
    };

    return (
        <Container>
            <ImageContainer>
                <CircleContainer dimension={circleDimension}>
                    <img
                        src={CameraSVG}
                        width={150}
                        height={150}
                        alt="camera"
                    />
                </CircleContainer>
            </ImageContainer>

            <TitleContainer>
                <BodyBold style={{ fontSize: width < 768 ? 22 : 30 }}>
                    Take Pictures
                </BodyBold>
            </TitleContainer>

            <ButtonsContainer>
                <Description>
                    Take photos of what you would like us to repair or modify
                </Description>

                <TakePhotoButton
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                        imageInputRef.current?.click();
                    }}
                >
                    Take a Photo
                </TakePhotoButton>

                <SkipButton
                    variant="outlined"
                    color="secondary"
                    onClick={handleSkip}
                >
                    Skip
                </SkipButton>
            </ButtonsContainer>
        </Container>
    );
};

export default StepTakePhoto;
