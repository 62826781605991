import { CheckCircleOutline } from '@mui/icons-material/';
import { useEffect, useState } from 'react';
import theme from '../../../theme';
import { SectionHeader } from '../../Text';
import { SectionWrapper } from '../../../screens/Landing/styles';

import Home from '../../../assets/icons/folders/home.svg';
import Briefcase from '../../../assets/icons/folders/work.svg';
import Teams from '../../../assets/icons/teamsIcon.svg';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import CTAButton from '../../Buttons/CTAButton';
import { onWebAppPricingTableCTAClick } from '../../../tracking/trackers';
import { CallToActions } from '../configuration/types';

const Check = ({
    compact,
    style,
}: {
    compact?: boolean;
    style?: React.CSSProperties;
}) => {
    return (
        <div
            style={{
                width: !compact ? '100%' : undefined,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...(style || {}),
            }}
        >
            <CheckCircleOutline
                style={{
                    color: theme.colors.primary400,
                    alignSelf: 'center',
                    width: 25,
                    height: 25,
                }}
            />
        </div>
    );
};

const Box = ({
    children,
    style,
}: {
    children?: React.ReactNode;
    style?: React.CSSProperties;
}) => {
    return (
        <div
            style={{
                width: 250,
                height: 70,
                border: '1px solid #ddd',
                fontSize: 14,
                lineHeight: 1.5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 10,
                borderTopWidth: 0,
                borderLeftWidth: 0,
                ...(style || {}),
            }}
        >
            {children}
        </div>
    );
};

const bulletPoints = [
    'Scan paper, email, Amazon, PayPal receipts',
    'Auto-import email, Amazon & PayPal receipts',
    'Create & share custom folders',
    'Export receipt images',
    'Auto-track mileage',
    'Auto-reconcile bank account with receipts',
    'Export spreadsheet reports & PDFs',
    'QuickBooks integration available for add-on',
    'Multiple admins',
    'View employee receipts in real-time reports',
    'Create & share custom folders across the team',
    '24/7 Phone Customer Support',
];

const PricingLabelColumn = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Box
                style={{
                    height: 350,
                    borderLeftWidth: 0,
                    justifyContent: 'flex-start',
                }}
            ></Box>
            {bulletPoints.map((bp, i) => (
                <Box
                    style={{
                        justifyContent: 'flex-start',
                        borderBottomWidth:
                            i === bulletPoints.length - 1 ? 0 : undefined,
                    }}
                >
                    {bp}
                </Box>
            ))}
        </div>
    );
};

const PricingTierColumn = (props: {
    icon: string;
    planName: string;
    description: string;
    price: string;
    subPrice?: string;
    rows: (string | boolean)[];
    lastColumn?: boolean;
    isPopular?: boolean;
    action?: CallToActions;
}) => {
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {props.isPopular ? (
                    <div
                        style={{
                            width: 250,
                            height: 1190,
                            border: `5px solid ${theme.colors.primary400}`,
                            position: 'absolute',
                        }}
                    >
                        <div
                            style={{
                                width: 250,
                                height: 40,
                                top: -40,
                                left: -5,
                                borderTopLeftRadius: 10,
                                borderTopRightRadius: 10,
                                position: 'absolute',
                                backgroundColor: theme.colors.primary400,
                                color: theme.colors.white,
                                fontSize: 18,
                                fontWeight: 'bold',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            Most Popular
                        </div>
                    </div>
                ) : null}
                <Box
                    style={{
                        height: 350,
                        borderLeftWidth: 0,
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRightWidth: props.lastColumn ? 0 : undefined,
                    }}
                >
                    <img src={props.icon} alt="" width={100} height={100} />
                    <h3 style={{ margin: 0, color: theme.colors.primary400 }}>
                        {props.planName}
                    </h3>
                    <div style={{ margin: 0, fontSize: 13 }}>
                        <strong style={{ fontSize: 23 }}>{props.price}</strong>
                        {props.subPrice || ''}/Year
                    </div>
                    <p
                        style={{
                            textAlign: 'center',
                            margin: 0,
                            lineHeight: 1.5,
                            marginTop: 20,
                            height: 80,
                        }}
                    >
                        {props.description}
                    </p>
                    <CTAButton
                        style={{
                            backgroundColor: theme.colors.primary400,
                            color: theme.colors.white,
                            borderRadius: 40,
                            width: 200,
                            minHeight: 50,
                            maxHeight: 50,
                            fontWeight: 'bold',
                            marginTop: 20,
                        }}
                        onClick={() => {
                            onWebAppPricingTableCTAClick({
                                plan: props.planName,
                                platform: 'desktop',
                            });
                        }}
                        text={'Get Started'}
                        action={props.action}
                    />
                </Box>
                {props.rows.map((r, i) => (
                    <Box
                        style={{
                            borderBottomWidth:
                                i === props.rows.length - 1 ? 0 : undefined,
                            borderRightWidth: props.lastColumn ? 0 : undefined,
                        }}
                    >
                        {r === true ? <Check /> : r === false ? <></> : r}
                    </Box>
                ))}
            </div>
        </>
    );
};

const PricingTierCard = (props: {
    icon: string;
    planName: string;
    description: string;
    price: string;
    subPrice?: string;
    rows: (string | boolean)[];
    isPopular?: boolean;
    action?: CallToActions;
}) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '90%',
                borderRadius: 10,
                margin: 10,
                paddingTop: 10,
                position: 'relative',
                border: props.isPopular
                    ? `5px solid ${theme.colors.primary400}`
                    : '1px solid #ddd',
                marginTop: props.isPopular ? 30 : 10,
            }}
        >
            {props.isPopular ? (
                <div
                    style={{
                        width: '102%',
                        height: 40,
                        top: -35,
                        left: -5,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        position: 'absolute',
                        backgroundColor: theme.colors.primary400,
                        color: theme.colors.white,
                        fontSize: 18,
                        fontWeight: 'bold',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    Most Popular
                </div>
            ) : null}
            <img src={props.icon} alt="" width={100} height={100} />
            <h3 style={{ margin: 0, color: theme.colors.primary400 }}>
                {props.planName}
            </h3>
            <div style={{ margin: 0, fontSize: 13 }}>
                <strong style={{ fontSize: 23 }}>{props.price}</strong>
                {props.subPrice || ''}/Year
            </div>
            <p
                style={{
                    textAlign: 'center',
                    margin: 0,
                    lineHeight: 1.5,
                    marginTop: 10,
                    minHeight: 110,
                    padding: 20,
                    marginBottom: 20,
                }}
            >
                {props.description}
            </p>
            {props.rows.map((r, i) =>
                r ? (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            width: '90%',
                            padding: 15,
                            borderTop: '1px solid #ddd',
                            lineHeight: 1.3,
                        }}
                    >
                        <Check compact style={{ marginRight: 15 }} />{' '}
                        {bulletPoints[i]}
                    </div>
                ) : null
            )}

            <CTAButton
                style={{
                    backgroundColor: theme.colors.primary400,
                    color: theme.colors.white,
                    borderRadius: 40,
                    width: '90%',
                    minHeight: 60,
                    fontWeight: 'bold',
                    marginTop: 20,
                    marginBottom: 30,
                }}
                onClick={() => {
                    onWebAppPricingTableCTAClick({
                        plan: props.planName,
                        platform: 'mobile',
                    });
                }}
                text="Get Started"
                action={props.action}
            />
        </div>
    );
};

export const PricingTable = ({ action }: { action?: CallToActions }) => {
    const { width } = useWindowDimensions();

    const [isDesktop, setIsDesktop] = useState(true);

    useEffect(() => {
        setIsDesktop(width > 1300);
    }, [width]);

    const freePlanProps = {
        planName: 'Free Plan',
        price: '$0',
        icon: Home,
        description: 'Perfect for getting started with receipt scanning.',
        rows: [
            '250',
            true,
            true,
            true,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
        ],
        action,
    };

    const personalPlanProps = {
        planName: 'Personal',
        price: '$29.99',
        icon: Home,
        description:
            'Our most basic plan, with unlimited receipt scanning & cloud storage.',
        rows: [
            'Unlimited',
            true,
            true,
            true,
            true,
            true,
            false,
            false,
            false,
            false,
            false,
            false,
        ],
        action,
    };

    const businessPlanProps = {
        planName: 'Business',
        price: '$89.99',
        icon: Briefcase,
        description:
            'Your best option for unlimited scanning, storage & expense tracking reports.',
        rows: [
            'Unlimited',
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            false,
            false,
            false,
            false,
        ],
        action,
        isPopular: true,
    };

    const teamsPlanProps = {
        planName: 'Teams Plan',
        price: '$89.99',
        subPrice: '/employee',
        icon: Teams,
        description:
            'Track team receipts in real-time. Get everything in our Business Plan plus the ability to view, edit & export employees receipts.',
        rows: [
            'Unlimited',
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
        ],
        action,
    };

    return (
        <SectionWrapper
            style={{
                backgroundColor: theme.colors.white,
                marginTop: 60,
                marginBottom: 60,
            }}
        >
            <SectionHeader
                style={{
                    textAlign: 'center',
                    margin: 40,
                    marginBottom: 60,
                }}
            >
                Pricing
            </SectionHeader>
            {isDesktop ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <PricingLabelColumn />
                    <PricingTierColumn {...freePlanProps} />
                    <PricingTierColumn {...personalPlanProps} />
                    <PricingTierColumn {...businessPlanProps} lastColumn />
                    {/* <PricingTierColumn {...teamsPlanProps} lastColumn /> */}
                </div>
            ) : (
                <>
                    <PricingTierCard {...freePlanProps} />
                    <PricingTierCard {...personalPlanProps} />
                    <PricingTierCard {...businessPlanProps} />
                    {/* <PricingTierCard {...teamsPlanProps} /> */}
                </>
            )}
        </SectionWrapper>
    );
};
