import React, { useEffect, useState, useMemo, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import {
    Box,
    Paper,
    Typography,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Button,
    Alert,
    Stack,
    CircularProgress,
    ListItemIcon,
    ListItemText,
    Dialog,
    List,
    ListItem,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Helmet } from 'react-helmet-async';
import EmailIcon from '@mui/icons-material/Email';
import ShareIcon from '@mui/icons-material/Share';
import {
    ISharedCostEstimation,
    ISharedCostEstimationLineItem,
} from '../../services/public/types';
import pageTheme from '../../theme';
import PublicService from '../../services/public/service';
import { formatDateWithShortMonth } from '../../util/formatDateWithShortMonth';
import { downloadComponentAsPDF } from '../../util/downloadComponentAsPDF';
import {
    onWebAppEstimationPreviewSend,
    onWebAppEstimationPreviewDownload,
    onWebAppEstimationPreviewApprove,
    onWebAppEstimationPreviewEdit,
    onWebAppEstimationPreviewOpen,
} from '../../tracking/trackers';
import { formatAmount } from '../../util/formatAmount';

const EstimationPreview = ({
    isContractor = false,
}: {
    isContractor?: boolean;
}) => {
    const { url } = useParams();
    const [estimateData, setEstimateData] =
        useState<ISharedCostEstimation | null>(null);
    // const signatureRef = useRef<SignatureCanvas>(null);
    // const [isDrawing, setIsDrawing] = useState(false);
    // const [hasSignature, setHasSignature] = useState(false);
    const [showInfoAlert, setShowInfoAlert] = useState(true);
    const [images, setImages] = useState<string[] | undefined>(undefined);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [showShareMenu, setShowShareMenu] = useState(false);

    useEffect(() => {
        const fetchImages = async () => {
            if (estimateData?.metadata?.image_ids) {
                const imageIds = estimateData.metadata.image_ids;
                try {
                    const imageData = await Promise.all(
                        imageIds.map(async (imageId) => {
                            const response = await PublicService.getImageData(
                                imageId
                            );
                            return response.data.image_data;
                        })
                    );
                    setImages(imageData);
                } catch (error) {
                    console.error('Error fetching images:', error);
                }
            } else {
                console.log('No image IDs found in metadata');
            }
        };
        fetchImages();
    }, [estimateData]);

    const handleEdit = () => {
        if (!estimateData) return;

        onWebAppEstimationPreviewEdit({
            receipt_id: estimateData.estimate.receipt_id,
        });

        window.location.href = `swce://open-estimate/${estimateData.estimate.receipt_id}`;
    };

    const handleEmailShare = () => {
        if (!estimateData) return;
        onWebAppEstimationPreviewSend({
            receipt_id: estimateData.estimate.receipt_id,
            url: `https://app.simplywise.com/estimation-preview/${url}`,
            method: 'email',
        });

        const clientEmail =
            estimateData.metadata.client_info?.client_email || '';
        const estimateName = estimateData.estimate.merchant || 'Estimate';
        const estimateNo = estimateData.metadata.estimate_no || estimateData.id;

        const subject = `${estimateName} #${estimateNo}`;
        const body =
            `Hey, sending over the estimate for your project. Let me know if I can answer any questions.\n\n` +
            `View estimate: https://app.simplywise.com/estimation-preview/${url}`;

        const mailtoLink = `mailto:${encodeURIComponent(
            clientEmail
        )}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
            body
        )}`;

        // Navigate to the mailto link
        window.location.href = mailtoLink;
    };

    const handleGeneralShare = () => {
        if (!estimateData) return;
        onWebAppEstimationPreviewSend({
            receipt_id: estimateData.estimate.receipt_id,
            url: `https://app.simplywise.com/estimation-preview/${url}`,
            method: 'general',
        });
        navigator
            .share({
                title: `${estimateData.estimate.merchant || 'Estimate'} #${
                    estimateData.metadata.estimate_no
                }`,
                text: 'Hey, sending over the estimate for your project. Let me know if I can answer any questions.',
                url: `https://app.simplywise.com/estimation-preview/${url}`,
            })
            .catch(console.error);
        setShowShareMenu(false);
    };

    const handleApprove = () => {
        if (!estimateData) return;

        onWebAppEstimationPreviewApprove({
            receipt_id: estimateData.estimate.receipt_id,
            client_name: estimateData.metadata.client_info?.client_name || '',
        });

        const message = `Hi ${estimateData.metadata.business_info?.business_name}, I approved your estimate. https://app.simplywise.com/estimation-preview/${url}`;
        const contractorPhoneNumber = estimateData.user.mobile;

        const smsLink = `sms:${contractorPhoneNumber}?&body=${encodeURIComponent(
            message
        )}`;
        window.location.href = smsLink;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await PublicService.getEstimationData(
                    url || ''
                );
                const estimationData: ISharedCostEstimation =
                    response.data.shared_cost_estimation;
                setEstimateData(estimationData);
            } catch (err) {
                console.error('Error:', err);
            }
        };
        if (url) fetchData();
    }, [url]);

    useEffect(() => {
        if (estimateData?.estimate.receipt_id) {
            onWebAppEstimationPreviewOpen({
                receipt_id: estimateData.estimate.receipt_id,
                is_contractor: isContractor,
            });

            if (
                !isContractor &&
                estimateData?.metadata?.notification_preferences?.seen &&
                url &&
                !localStorage.getItem('notification_sent__seen')
            ) {
                PublicService.sharedEstimateNotify(url, 'seen');
                localStorage.setItem('notification_sent__seen', 'true');
            }
        }
    }, [estimateData?.estimate.receipt_id, isContractor]);

    // Calculate totals
    const calculateTotal = (): {
        subtotal: number;
        tax: number;
        total: number;
        hasTax: boolean;
        markup: number;
    } => {
        if (!estimateData?.estimate)
            return { subtotal: 0, tax: 0, total: 0, hasTax: false, markup: 0 };

        // Calculate subtotal by summing all non-tax line items
        const subtotal =
            estimateData.estimate.line_items?.reduce(
                (sum: number, item: ISharedCostEstimationLineItem) => {
                    if (item.group === 'other' && item.item === 'Tax') {
                        return sum; // Skip tax line item
                    }
                    return sum + parseFloat((item.total || '0').toString());
                },
                0
            ) || 0;

        // Find tax line item
        const taxItem = estimateData.estimate.line_items?.find(
            (item: ISharedCostEstimationLineItem) =>
                item.group === 'other' &&
                item.amount_type === 'percentage' &&
                item.item === 'Tax'
        );

        // Get tax amount directly from the tax line item's total
        const tax = taxItem ? parseFloat((taxItem.total || '0').toString()) : 0;

        // Find markup line item
        const markupItem = estimateData.estimate.line_items?.find(
            (item: ISharedCostEstimationLineItem) =>
                item.group === 'other' &&
                item.amount_type === 'percentage' &&
                item.item === 'Markup'
        );

        const markup = markupItem
            ? parseFloat((markupItem.total || '0').toString())
            : 0;

        return {
            subtotal,
            tax,
            total: subtotal + tax,
            hasTax: Boolean(taxItem),
            markup,
        };
    };
    const totals = calculateTotal();

    const groupedLineItems = estimateData?.estimate.line_items
        .map((l: any, i: number) => ({ ...l, index: i }))
        .reduce((acc: any, l: any) => {
            const showLineItems = Boolean(
                estimateData.metadata.show_line_items
            );
            const showMarkup =
                !Boolean(estimateData.metadata.prorate_markup) &&
                estimateData.metadata.show_markup !== false;

            if (estimateData.metadata.prorate_markup) {
                const proratedMarkup =
                    totals.markup /
                    estimateData?.estimate.line_items.filter(
                        (l) =>
                            l.amount_type !== 'percentage' &&
                            l.group !== 'labor'
                    ).length;
                if (l.amount_type !== 'percentage' && l.group !== 'labor')
                    l.total += proratedMarkup;
            }
            const isMarkupItem = l.group === 'other' && l.item === 'Markup';

            if (!showLineItems) {
                if (!isMarkupItem) return acc;
            }

            if (!showMarkup) {
                if (isMarkupItem) return acc;
            }

            // Scenario 1: Show both (no filtering needed)
            const group = acc.find((el: any) => el.trade === l.trade);
            const groupIndex = acc.findIndex((el: any) => el.trade === l.trade);

            if (group) {
                if (!group[l.group]) {
                    group[l.group] = [];
                }
                group[l.group].push(l);
                acc[groupIndex] = group;
            } else {
                acc.push({
                    trade: l.trade,
                    labor: [],
                    material: [],
                    equipment: [],
                    other: [],
                });
                if (!acc[acc.length - 1][l.group]) {
                    acc[acc.length - 1][l.group] = [];
                }
                acc[acc.length - 1][l.group].push(l);
            }

            return acc;
        }, [])
        .sort((a: any, b: any) => {
            // Move 'Other' group with markup to the end
            const aHasMarkup = a.other?.some(
                (item: any) => item.item === 'Markup'
            );
            const bHasMarkup = b.other?.some(
                (item: any) => item.item === 'Markup'
            );

            if (aHasMarkup && !bHasMarkup) return 1;
            if (!aHasMarkup && bHasMarkup) return -1;
            return 0;
        });

    const helmet = (
        <Helmet>
            <title>Cost Estimator | SimplyWise</title>
            <meta
                name="description"
                content="Review and manage cost estimations for your projects | SimplyWise"
            />
            <link
                rel="canonical"
                href="https://www.simplywise.com/estimation-preview"
            />
        </Helmet>
    );

    if (!estimateData)
        return (
            <>
                {helmet}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: '100vh',
                        gap: 2,
                        fontFamily: 'Inter, sans-serif',
                    }}
                >
                    <CircularProgress />
                    <Typography>Loading Estimate...</Typography>
                </Box>
            </>
        );

    const businessLogo = estimateData?.metadata?.business_info?.business_logo;

    // Display % instead of $ for tax and markup
    const formatUnitPrice = (item: ISharedCostEstimationLineItem) => {
        if (
            item.amount_type === 'percentage' &&
            (item.item === 'Tax' || item.item === 'Markup')
        ) {
            return `${item.unit_price}%`;
        }
        return `$${item.unit_price}`;
    };

    return (
        <>
            {helmet}
            <Box
                sx={{
                    maxWidth: 1200,
                    margin: '0 auto',
                    bgcolor: '#fff !important',
                    padding: { xs: 0, md: '24px' },
                    fontFamily: 'Inter, sans-serif',
                }}
            >
                {/* Header with Close Button */}
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography
                        color={pageTheme.colors.text}
                        sx={{
                            fontWeight: 'bold',
                            fontSize: {
                                xs: '1rem',
                                sm: '1.25rem',
                            },
                            textAlign: 'left',
                            p: '10px 16px',
                        }}
                    >
                        Review Estimate
                    </Typography>
                </Stack>

                {/* Info Alert */}
                {showInfoAlert && isContractor && (
                    <Alert
                        severity="info"
                        sx={{
                            'mb': 2,
                            'backgroundColor': '#FDF4E7',
                            '& .MuiAlert-message': {
                                display: 'flex',
                                justifyContent: 'flex-start',
                                width: '100%',
                            },
                            '& .MuiAlert-icon': {
                                display: 'none',
                            },
                            'ml': { xs: '16px', md: 0 },
                            'mr': { xs: '16px', md: 0 },
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' },
                                alignItems: { xs: 'stretch', sm: 'center' },
                                justifyContent: 'space-between',
                                gap: 2,
                                color: pageTheme.colors.text,
                                width: '100%',
                            }}
                        >
                            <Typography
                                fontSize={{ xs: '0.75rem', sm: '0.875rem' }}
                                sx={{
                                    flex: 1,
                                    textAlign: 'left',
                                }}
                            >
                                This is a preview of how your estimate will
                                appear to your client.
                            </Typography>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={() => setShowInfoAlert(false)}
                                sx={{
                                    'backgroundColor': '#002A3C',
                                    'borderRadius': '4px',
                                    '&:hover': {
                                        backgroundColor: '#002A3C',
                                    },
                                    'whiteSpace': 'nowrap',
                                    'padding': '3px 10px',
                                    'fontSize': {
                                        xs: '0.75rem',
                                        sm: '0.75rem',
                                    },
                                    'alignSelf': 'flex-start',
                                }}
                            >
                                Got it
                            </Button>
                        </Box>
                    </Alert>
                )}

                {/* Main Content Section */}
                <Paper
                    id="estimation-preview-component"
                    elevation={0}
                    sx={{
                        border: `2px solid ${pageTheme.colors.border}`,
                    }}
                >
                    {/* Estimate Header */}
                    <Box
                        sx={{
                            p: '6px 16px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Stack direction="row">
                            <Box>
                                <Typography
                                    color={pageTheme.colors.text}
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: {
                                            xs: '1rem',
                                            sm: '1.25rem',
                                        },
                                        mb: 0.5,
                                        textAlign: 'left',
                                    }}
                                >
                                    Estimate #
                                    {estimateData.metadata.estimate_no
                                        ? estimateData.metadata.estimate_no
                                        : estimateData.id}
                                </Typography>
                                <Typography
                                    fontSize={{ xs: '0.75rem', sm: '0.875rem' }}
                                    color={pageTheme.colors.text}
                                    sx={{ textAlign: 'left' }}
                                >
                                    Date:{' '}
                                    {formatDateWithShortMonth(
                                        new Date(
                                            estimateData.estimate.date ?? ''
                                        )
                                    )}
                                </Typography>
                                {/* <Typography
                                fontSize={{ xs: '0.75rem', sm: '0.875rem' }}
                                color={pageTheme.colors.grayText}
                                sx={{
                                    mt: 1,
                                    maxWidth: '600px',
                                    lineHeight: 1.5,
                                    textAlign: 'left',
                                }}
                            >
                                {(
                                    estimateData.estimate.notes ||
                                    'No description provided'
                                )
                                    .charAt(0)
                                    .toUpperCase() +
                                    (
                                        estimateData.estimate.notes ||
                                        'No description provided'
                                    ).slice(1)}
                            </Typography> */}
                            </Box>
                        </Stack>
                        {businessLogo && (
                            <Box>
                                <img
                                    src={`data:image/png;base64,${businessLogo}`}
                                    alt="Business Logo"
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                        objectFit: 'contain',
                                    }}
                                />
                            </Box>
                        )}
                    </Box>

                    {/* Business and Client Information */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                        }}
                    >
                        {/* Business Information Section */}
                        {estimateData.metadata.business_info &&
                            Object.values(
                                estimateData.metadata.business_info
                            ).some((value) => value) && (
                                <Box sx={{ width: '100%' }}>
                                    <Box
                                        sx={{
                                            bgcolor: '#D9D9D9',
                                            padding: '6px 16px',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: {
                                                    xs: '0.875rem',
                                                    sm: '1rem',
                                                },
                                                fontWeight: 'bold',
                                            }}
                                            color={pageTheme.colors.text}
                                        >
                                            Business Information
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            p: '6px 16px',
                                            pl: { xs: '16px', sm: '20px' },
                                        }}
                                    >
                                        <Typography
                                            sx={{ minHeight: '24px' }}
                                            fontWeight="bold"
                                            fontSize={{
                                                xs: '0.75rem',
                                                sm: '0.875rem',
                                            }}
                                        >
                                            {estimateData.metadata.business_info
                                                ?.business_name || ''}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: 1,
                                                justifyContent: 'baseline',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.75rem',
                                                        sm: '0.9rem',
                                                    },
                                                }}
                                                color={pageTheme.colors.text}
                                            >
                                                Address:
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.75rem',
                                                        sm: '0.9rem',
                                                    },
                                                }}
                                                color={
                                                    pageTheme.colors.grayText
                                                }
                                            >
                                                {estimateData.metadata
                                                    .business_info
                                                    ?.business_address ||
                                                    '\u00A0'}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: 1,
                                                justifyContent: 'baseline',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.75rem',
                                                        sm: '0.9rem',
                                                    },
                                                }}
                                                color={pageTheme.colors.text}
                                            >
                                                Email:
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.75rem',
                                                        sm: '0.9rem',
                                                    },
                                                }}
                                                color={
                                                    pageTheme.colors.grayText
                                                }
                                            >
                                                {estimateData.metadata
                                                    .business_info
                                                    ?.business_email ||
                                                    '\u00A0'}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            )}

                        {/* Client Information Section */}
                        {estimateData.metadata.client_info &&
                            Object.values(
                                estimateData.metadata.client_info
                            ).some((value) => value) && (
                                <Box sx={{ width: '100%' }}>
                                    <Box
                                        sx={{
                                            bgcolor: '#D9D9D9',
                                            padding: {
                                                xs: '6px 16px',
                                                sm: '6px 6px',
                                            },
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: {
                                                    xs: '0.875rem',
                                                    sm: '1rem',
                                                },
                                                fontWeight: 'bold',
                                            }}
                                            color={pageTheme.colors.text}
                                        >
                                            Client Information
                                        </Typography>
                                    </Box>
                                    <Box sx={{ p: '6px 16px' }}>
                                        <Typography
                                            sx={{ minHeight: '24px' }}
                                            fontWeight="bold"
                                            fontSize={{
                                                xs: '0.75rem',
                                                sm: '0.875rem',
                                            }}
                                        >
                                            {estimateData.metadata.client_info
                                                ?.client_name || '\u00A0'}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: 1,
                                                justifyContent: 'baseline',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.75rem',
                                                        sm: '0.9rem',
                                                    },
                                                }}
                                                color={pageTheme.colors.text}
                                            >
                                                Address:
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.75rem',
                                                        sm: '0.9rem',
                                                    },
                                                }}
                                                color={
                                                    pageTheme.colors.grayText
                                                }
                                            >
                                                {estimateData.metadata
                                                    .client_info
                                                    ?.client_address ||
                                                    '\u00A0'}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: 1,
                                                justifyContent: 'baseline',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.75rem',
                                                        sm: '0.9rem',
                                                    },
                                                }}
                                                color={pageTheme.colors.text}
                                            >
                                                Email:
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.75rem',
                                                        sm: '0.9rem',
                                                    },
                                                }}
                                                color={
                                                    pageTheme.colors.grayText
                                                }
                                            >
                                                {estimateData.metadata
                                                    .client_info
                                                    ?.client_email || '\u00A0'}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                    </Box>
                    {/*Project Breakdown*/}
                    {estimateData?.metadata?.show_project_breakdown && (
                        <Box sx={{ width: '100%' }}>
                            <Box
                                sx={{
                                    bgcolor: '#D9D9D9',
                                    padding: '6px 16px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: {
                                            xs: '0.875rem',
                                            sm: '1rem',
                                        },
                                        fontWeight: 'bold',
                                    }}
                                    color={pageTheme.colors.text}
                                >
                                    Project Breakdown
                                </Typography>
                            </Box>
                            <Box sx={{ p: '6px 16px' }}>
                                <Typography
                                    sx={{
                                        fontSize: {
                                            xs: '0.75rem',
                                            sm: '0.875rem',
                                        },
                                        whiteSpace: 'pre-line', // Preserves line breaks (\n), collapses spaces, allows text wrapping
                                    }}
                                >
                                    {/* Converts string '\n' to actual line breaks, then pre-line renders them as new lines */}
                                    {estimateData?.metadata?.project_breakdown?.replace(
                                        /\\n/g,
                                        '\n'
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    {/* Costs Table */}
                    <Box sx={{ width: '100%' }}>
                        <Box
                            sx={{
                                bgcolor: '#D9D9D9',
                                padding: '6px 16px',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: {
                                        xs: '0.875rem',
                                        sm: '1rem',
                                    },
                                    fontWeight: 'bold',
                                }}
                                color={pageTheme.colors.text}
                            >
                                Costs Table
                            </Typography>
                        </Box>

                        {(estimateData.metadata.show_line_items ||
                            estimateData.metadata.show_markup) && (
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow
                                            sx={{
                                                bgcolor: '#f2f2f2 !important',
                                            }}
                                        >
                                            <TableCell
                                                align="left"
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.8rem',
                                                        sm: '0.9rem',
                                                    },
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Item Description
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.8rem',
                                                        sm: '0.9rem',
                                                    },
                                                    fontWeight: 'bold',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Unit Price
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.8rem',
                                                        sm: '0.9rem',
                                                    },
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Total
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {groupedLineItems?.map(
                                            (
                                                group: any,
                                                groupIndex: number
                                            ) => (
                                                <Fragment key={groupIndex}>
                                                    {/* Group Title Row */}
                                                    <TableRow
                                                        sx={{
                                                            bgcolor:
                                                                '#f5f5f5  !important',
                                                        }}
                                                    >
                                                        <TableCell colSpan={3}>
                                                            <Typography
                                                                sx={{
                                                                    fontWeight:
                                                                        'bold',
                                                                    fontSize: {
                                                                        xs: '0.7rem',
                                                                        sm: '0.85rem',
                                                                    },
                                                                }}
                                                            >
                                                                {group.trade}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    {/* SubGroups */}
                                                    {Object.entries(group)
                                                        .filter(
                                                            ([key, items]) =>
                                                                key !==
                                                                    'trade' &&
                                                                Array.isArray(
                                                                    items
                                                                ) &&
                                                                (items as any[])
                                                                    .length > 0
                                                        )
                                                        .map(
                                                            ([
                                                                category,
                                                                items,
                                                            ]) => (
                                                                <Fragment
                                                                    key={`${group.trade}-${category}`}
                                                                >
                                                                    {/* Category Title Row with Total */}
                                                                    {!(
                                                                        category ===
                                                                            'other' &&
                                                                        group.trade ===
                                                                            'Other'
                                                                    ) ? (
                                                                        <TableRow
                                                                            sx={{
                                                                                bgcolor:
                                                                                    '#f8f8f8 !important',
                                                                                // bgcolor:
                                                                                //     'red !important',
                                                                                paddingLeft: 2,
                                                                            }}
                                                                        >
                                                                            <TableCell
                                                                                sx={{
                                                                                    fontSize:
                                                                                        {
                                                                                            xs: '0.7rem',
                                                                                            sm: '0.85rem',
                                                                                        },
                                                                                }}
                                                                            >
                                                                                {category
                                                                                    .charAt(
                                                                                        0
                                                                                    )
                                                                                    .toUpperCase() +
                                                                                    category.slice(
                                                                                        1
                                                                                    )}
                                                                            </TableCell>

                                                                            {/* Second Column - Empty for Unit Price */}
                                                                            <TableCell align="center"></TableCell>

                                                                            {/* Third Column - Total */}
                                                                            <TableCell
                                                                                align="right"
                                                                                sx={{
                                                                                    fontSize:
                                                                                        {
                                                                                            xs: '0.7rem',
                                                                                            sm: '0.85rem',
                                                                                        },
                                                                                }}
                                                                            >
                                                                                {formatAmount(
                                                                                    (
                                                                                        items as any[]
                                                                                    ).reduce(
                                                                                        (
                                                                                            sum: number,
                                                                                            item: any
                                                                                        ) =>
                                                                                            sum +
                                                                                            parseFloat(
                                                                                                item.total ||
                                                                                                    '0'
                                                                                            ),
                                                                                        0
                                                                                    )
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ) : null}
                                                                    {(
                                                                        items as any[]
                                                                    ).map(
                                                                        (
                                                                            item: any,
                                                                            index: number
                                                                        ) => (
                                                                            <TableRow
                                                                                key={`${category}-${index}`}
                                                                                sx={{
                                                                                    bgcolor:
                                                                                        'white !important',
                                                                                }}
                                                                            >
                                                                                <TableCell
                                                                                    sx={{
                                                                                        pl: 4,
                                                                                    }}
                                                                                >
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        sx={{
                                                                                            fontSize:
                                                                                                {
                                                                                                    xs: '0.65rem',
                                                                                                    sm: '0.8rem',
                                                                                                },
                                                                                            textAlign:
                                                                                                'left',
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            item.item
                                                                                        }
                                                                                    </Typography>
                                                                                    {item.item !==
                                                                                        'Markup' && (
                                                                                        <Typography
                                                                                            variant="caption"
                                                                                            color="text.secondary"
                                                                                            sx={{
                                                                                                fontSize:
                                                                                                    {
                                                                                                        xs: '0.6rem',
                                                                                                        sm: '0.75rem',
                                                                                                    },
                                                                                                textAlign:
                                                                                                    'left',
                                                                                            }}
                                                                                        >
                                                                                            Quantity:{' '}
                                                                                            {
                                                                                                item.quantity
                                                                                            }
                                                                                        </Typography>
                                                                                    )}
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        margin="auto"
                                                                                        sx={{
                                                                                            fontSize:
                                                                                                {
                                                                                                    xs: '0.65rem',
                                                                                                    sm: '0.8rem',
                                                                                                },
                                                                                        }}
                                                                                    >
                                                                                        {item.unit_price &&
                                                                                            formatUnitPrice(
                                                                                                item
                                                                                            )}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                                <TableCell align="right">
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        align="right"
                                                                                        sx={{
                                                                                            fontSize:
                                                                                                {
                                                                                                    xs: '0.65rem',
                                                                                                    sm: '0.8rem',
                                                                                                },
                                                                                        }}
                                                                                    >
                                                                                        {formatAmount(
                                                                                            parseFloat(
                                                                                                item.total ||
                                                                                                    '0'
                                                                                            )
                                                                                        )}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    )}
                                                                </Fragment>
                                                            )
                                                        )}
                                                </Fragment>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Box>

                    {/* Totals Section */}
                    <Box sx={{ p: 3 }}>
                        <Stack spacing={2}>
                            <Box
                                sx={{
                                    'display': 'flex',
                                    'justifyContent': 'space-between',
                                    'alignItems': 'center',
                                    'position': 'relative',
                                    '&::after': {
                                        content: '""',
                                        position: 'absolute',
                                        left: '80px',
                                        right: '80px',
                                        top: '50%',
                                        borderBottom: '3px dotted #ccc',
                                    },
                                }}
                            >
                                <Typography
                                    sx={{
                                        bgcolor: '#fff',
                                        position: 'relative',
                                        zIndex: 1,
                                        fontSize: {
                                            xs: '0.7rem',
                                            sm: '1rem',
                                        },
                                    }}
                                >
                                    Sub Total
                                </Typography>
                                <Typography
                                    sx={{
                                        bgcolor: '#fff',
                                        position: 'relative',
                                        zIndex: 1,
                                        fontSize: {
                                            xs: '0.7rem',
                                            sm: '1rem',
                                        },
                                    }}
                                >
                                    {formatAmount(totals.subtotal)}
                                </Typography>
                            </Box>
                            {totals.hasTax && (
                                <Box
                                    sx={{
                                        'display': 'flex',
                                        'justifyContent': 'space-between',
                                        'alignItems': 'center',
                                        'position': 'relative',
                                        '&::after': {
                                            content: '""',
                                            position: 'absolute',
                                            left: '80px',
                                            right: '80px',
                                            top: '50%',
                                            borderBottom: '3px dotted #ccc',
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            bgcolor: '#fff',
                                            position: 'relative',
                                            zIndex: 1,
                                            fontSize: {
                                                xs: '0.7rem',
                                                sm: '1rem',
                                            },
                                        }}
                                    >
                                        Tax
                                    </Typography>
                                    <Typography
                                        sx={{
                                            bgcolor: '#fff',
                                            position: 'relative',
                                            zIndex: 1,
                                            fontSize: {
                                                xs: '0.7rem',
                                                sm: '1rem',
                                            },
                                        }}
                                    >
                                        {formatAmount(totals.tax)}
                                    </Typography>
                                </Box>
                            )}
                            <Box
                                sx={{
                                    'display': 'flex',
                                    'justifyContent': 'space-between',
                                    'alignItems': 'center',
                                    'position': 'relative',
                                    '&::after': {
                                        content: '""',
                                        position: 'absolute',
                                        left: '80px',
                                        right: '80px',
                                        top: '50%',
                                        borderBottom: '3px dotted #ccc',
                                    },
                                }}
                            >
                                <Typography
                                    color={pageTheme.colors.text}
                                    sx={{
                                        position: 'relative',
                                        zIndex: 1,
                                        fontWeight: 'bold',
                                        fontSize: {
                                            xs: '0.8rem',
                                            sm: '1.25rem',
                                        },
                                    }}
                                >
                                    Total
                                </Typography>
                                <Typography
                                    sx={{
                                        bgcolor: '#fff',
                                        position: 'relative',
                                        zIndex: 1,
                                        fontWeight: 'bold',
                                        fontSize: {
                                            xs: '0.8rem',
                                            sm: '1.25rem',
                                        },
                                    }}
                                >
                                    {formatAmount(totals.total)}
                                </Typography>
                            </Box>
                        </Stack>
                    </Box>

                    {/* Notes Section */}
                    <Box sx={{ width: '100%', mb: 3 }}>
                        <Box sx={{ p: 3 }}>
                            <Typography
                                variant="body1"
                                color={pageTheme.colors.text}
                                sx={{
                                    fontSize: {
                                        xs: '0.875rem',
                                        sm: '1rem',
                                    },
                                    fontWeight: 'bold',
                                }}
                            >
                                Notes:{' '}
                                <Typography
                                    variant="body1"
                                    color={pageTheme.colors.text}
                                    sx={{
                                        fontSize: {
                                            xs: '0.75rem',
                                            sm: '0.875rem',
                                        },
                                    }}
                                >
                                    {estimateData.metadata.notes_content
                                        .project_notes || 'No notes provided'}
                                </Typography>
                            </Typography>
                        </Box>
                    </Box>

                    {/* Image Section */}
                    {images && images.length > 0 && (
                        <Box
                            sx={{
                                'width': '100%',
                                'p': '16px',
                                'display': 'flex',
                                'mx': '16px',
                                'flexWrap': 'wrap',
                                'gap': '16px',
                                '& img': {
                                    width: { xs: '6rem', sm: '12rem' },
                                    height: { xs: '6rem', sm: '12rem' },
                                    objectFit: 'cover',
                                    cursor: 'pointer',
                                    borderRadius: '4px',
                                },
                            }}
                        >
                            {images.map((image, index) => (
                                <img
                                    key={index}
                                    src={`data:image/png;base64,${image}`}
                                    alt={`${index + 1}`}
                                    onClick={() => setSelectedImage(image)}
                                />
                            ))}
                        </Box>
                    )}
                </Paper>

                {/* Image Enlarged View */}
                <Dialog
                    open={Boolean(selectedImage)}
                    onClose={() => setSelectedImage(null)}
                    maxWidth="xl"
                    fullWidth
                >
                    <Box
                        sx={{
                            position: 'relative',
                            backgroundColor: 'black',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '90vh',
                        }}
                    >
                        <IconButton
                            onClick={() => setSelectedImage(null)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: 'white',
                                zIndex: 1,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        {selectedImage && (
                            <img
                                src={`data:image/png;base64,${selectedImage}`}
                                alt="Enlarged view"
                                style={{
                                    maxWidth: '95%',
                                    maxHeight: '85vh',
                                    objectFit: 'contain',
                                    margin: 'auto',
                                }}
                            />
                        )}
                    </Box>
                </Dialog>

                {/* Action Buttons */}
                <Grid
                    container
                    spacing={2}
                    sx={{
                        flexDirection: 'row',
                        p: '16px',
                        paddingTop: 0,
                        position: 'sticky',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: '#fff',
                        zIndex: 1000,
                    }}
                >
                    {isContractor ? (
                        <>
                            <Grid item xs={6} sm={6}>
                                <Button
                                    fullWidth
                                    sx={{
                                        'borderRadius': '0.25rem',
                                        'border': `2px solid ${pageTheme.colors.primary400}`,
                                        'color': pageTheme.colors.primary400,
                                        '&:hover': {
                                            bgcolor: '#5DADE2',
                                        },
                                        'fontWeight': '700',
                                    }}
                                    onClick={handleEdit}
                                >
                                    Back
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Button
                                    fullWidth
                                    sx={{
                                        'borderRadius': '0.25rem',
                                        'bgcolor': pageTheme.colors.primary400,
                                        'color': '#fff',
                                        '&:hover': {
                                            bgcolor: '#5DADE2',
                                        },
                                        'fontWeight': '700',
                                    }}
                                    onClick={() => setShowShareMenu(true)}
                                >
                                    Share Estimate
                                </Button>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={6} sm={6}>
                                <Button
                                    fullWidth
                                    sx={{
                                        'borderRadius': '0.25rem',
                                        'bgcolor': pageTheme.colors.primary400,
                                        'color': '#fff',
                                        '&:hover': {
                                            bgcolor: '#5DADE2',
                                        },
                                        'fontWeight': '700',
                                    }}
                                    onClick={handleApprove}
                                >
                                    Approve
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Button
                                    fullWidth
                                    sx={{
                                        'borderRadius': '0.25rem',
                                        'bgcolor': pageTheme.colors.primary400,
                                        'color': '#fff',
                                        '&:hover': {
                                            bgcolor: '#5DADE2',
                                        },
                                        'fontWeight': '700',
                                    }}
                                    onClick={() => {
                                        onWebAppEstimationPreviewDownload({
                                            receipt_id:
                                                estimateData.estimate
                                                    .receipt_id,
                                        });
                                        downloadComponentAsPDF(
                                            'estimation-preview-component',
                                            'Estimate'
                                        );
                                    }}
                                >
                                    Download
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Box>

            {/* Show menu with sharing options */}
            <Dialog
                open={showShareMenu}
                onClose={() => setShowShareMenu(false)}
                PaperProps={{
                    sx: {
                        borderRadius: '12px',
                        minWidth: '300px',
                        padding: '8px',
                    },
                }}
            >
                <List sx={{ p: 0 }}>
                    <ListItem
                        onClick={handleEmailShare}
                        sx={{
                            'borderRadius': '8px',
                            'mb': 1,
                            'cursor': 'pointer',
                            '&:hover': {
                                'bgcolor': pageTheme.colors.primary400,
                                '& .MuiListItemIcon-root, & .MuiListItemText-primary':
                                    {
                                        color: 'white',
                                    },
                            },
                        }}
                    >
                        <ListItemIcon sx={{ minWidth: '40px' }}>
                            <EmailIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary="Email"
                            primaryTypographyProps={{
                                sx: { fontWeight: 500 },
                            }}
                        />
                    </ListItem>
                    <ListItem
                        onClick={handleGeneralShare}
                        sx={{
                            'borderRadius': '8px',
                            'cursor': 'pointer',
                            '&:hover': {
                                'bgcolor': pageTheme.colors.primary400,
                                '& .MuiListItemIcon-root, & .MuiListItemText-primary':
                                    {
                                        color: 'white',
                                    },
                            },
                        }}
                    >
                        <ListItemIcon sx={{ minWidth: '40px' }}>
                            <ShareIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary="Other sharing options"
                            primaryTypographyProps={{
                                sx: { fontWeight: 500 },
                            }}
                        />
                    </ListItem>
                </List>
            </Dialog>
        </>
    );
};

export default EstimationPreview;
