import { createContext, useContext, useEffect, useState } from 'react';
import { callClaude } from '../../../CostEstimator/components/CostEstimatorProvider/service';
import { FURTHER_QUESTIONS_PROMPT } from '../../../CostEstimator/components/CostEstimatorProvider/prompts';
import PublicService from '../../../../services/public/service';

type QuestionaireContextType = {
    step: string;
    setStep: (step: string) => void;
    formId: number | undefined;
    setFormId: (id: number | undefined) => void;
    setImageIds: (ids: string[] | undefined) => void;
    setImageUri: (uri: string | undefined) => void;
    imageIds: string[] | undefined;
    imageUri: string | undefined;
    prompt: string | undefined;
    setPrompt: (prompt: string | undefined) => void;
    questionsToAnswer: any[];
    setQuestionsToAnswer: (questions: any[]) => void;
    generateQuestions: () => void;
    initialQuestionCount: number;
    setInitialQuestionCount: (count: number) => void;
    currentQuestionIndex: number;
    setCurrentQuestionIndex: (index: number) => void;
    drafting: boolean;
    setDrafting: (drafting: boolean) => void;
    submitProgressToForm: (stepData: {
        image_ids?: string[];
        project_description?: string;
        questions_answers?: { question: string; answer: string }[];
        client_info?: {
            name: string;
            address: string;
            mobile: string;
            email: string;
        };
        project_name?: string;
    }) => Promise<void>;
    projectName: string | undefined;
    setProjectName: (name: string | undefined) => void;
};

const QuestionaireContext = createContext<QuestionaireContextType>({
    step: 'start',
    setStep: () => {},
    formId: undefined,
    setFormId: () => {},
    setImageIds: () => {},
    setImageUri: () => {},
    imageIds: undefined,
    imageUri: undefined,
    prompt: undefined,
    setPrompt: () => {},
    questionsToAnswer: [],
    setQuestionsToAnswer: () => {},
    generateQuestions: () => {},
    initialQuestionCount: 0,
    setInitialQuestionCount: () => {},
    currentQuestionIndex: 0,
    setCurrentQuestionIndex: () => {},
    drafting: false,
    setDrafting: () => {},
    submitProgressToForm: () => Promise.resolve(),
    projectName: undefined,
    setProjectName: () => {},
});

export const QuestionaireProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [step, setStep] = useState<string>('start');
    const [formId, setFormId] = useState<number | undefined>(undefined);
    const [imageIds, setImageIds] = useState<string[] | undefined>(undefined);
    const [imageUri, setImageUri] = useState<string | undefined>(undefined);
    const [prompt, setPrompt] = useState<string | undefined>(undefined);
    const [questionsToAnswer, setQuestionsToAnswer] = useState<any[]>([]);
    const [initialQuestionCount, setInitialQuestionCount] = useState<number>(0);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
    const [drafting, setDrafting] = useState<boolean>(false);
    const [projectName, setProjectName] = useState<string | undefined>(
        undefined
    );

    useEffect(() => {
        if (prompt && prompt.length > 0 && step === 'client-info-step') {
            callClaude({
                instructions:
                    'Based on this project description, create a descriptive name for the project. Only return the name and nothing else',
                prompt: prompt,
                quick: true,
            }).then((res) => {
                setProjectName(res.response);
            });
        }
    }, [prompt, step]);

    const generateQuestions = async () => {
        setCurrentQuestionIndex(0);
        const { response } = await callClaude({
            instructions: FURTHER_QUESTIONS_PROMPT + '',
            prompt: prompt ?? '',
            imageUri: imageUri ?? '',
        });
        const questions = JSON.parse(
            response.split('<questions>')[1].split('</questions>')[0]
        ).questions;
        setQuestionsToAnswer(questions);
        setInitialQuestionCount(questions.length);
    };

    const submitProgressToForm = async (formData: {
        image_ids?: string[];
        project_description?: string;
        questions_answers?: { question: string; answer: string }[];
        client_info?: {
            name: string;
            address: string;
            mobile: string;
            email: string;
        };
        project_name?: string;
    }) => {
        if (!formId) return;
        await PublicService.submitCostEstimatorQuestionaireForm(
            formId,
            formData
        );
    };

    return (
        <QuestionaireContext.Provider
            value={{
                step,
                setStep,
                formId,
                setFormId,
                imageIds,
                setImageIds,
                imageUri,
                setImageUri,
                prompt,
                setPrompt,
                questionsToAnswer,
                setQuestionsToAnswer,
                generateQuestions,
                initialQuestionCount,
                setInitialQuestionCount,
                currentQuestionIndex,
                setCurrentQuestionIndex,
                drafting,
                setDrafting,
                submitProgressToForm,
                projectName,
                setProjectName,
            }}
        >
            {children}
        </QuestionaireContext.Provider>
    );
};

export const useQuestionaire = () => useContext(QuestionaireContext);
