import styled from 'styled-components';
import theme from '../../theme';

export const QuestionaireContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: theme.colors.background,
});

export const ContentContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    marginTop: -20, // Overlap with header
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: theme.colors.white,
    borderRadius: 20,
    padding: 20,
    width: '100%',
    height: '100vh',
    boxShadow: theme.spreadShadow,
    zIndex: 1,
    position: 'relative',
});
