import { useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import Resizer from 'react-image-file-resizer';
import PublicService from '../../services/public/service';
import { onWebAppCostEstimationBookingLinkStep } from '../../tracking/trackers';
import { QuestionaireContainer, ContentContainer } from './styles';
import QuestionaireHeader from './components/QuestionaireHeader';
import StepTakePhoto from './components/StepTakePhoto';
import StepStart from './components/StepStart';
import { useQuestionaire } from './components/QuestionaireProvider';
import StepDescription from './components/StepDescription';
import StepQuestions from './components/StepQuestions';
import StepClientInfo from './components/StepClientInfo';
import StepDone from './components/StepDone';
const CostEstimatorQuestionaire = () => {
    const {
        step,
        setStep,
        setFormId,
        setImageIds,
        setImageUri,
        submitProgressToForm,
        formId,
    } = useQuestionaire();
    const { url } = useParams();
    const imageInputRef = useRef<HTMLInputElement>(null);
    const [companyName, setCompanyName] = useState('SimplyWise');

    useEffect(() => {
        const fetchBookingLink = async () => {
            if (!url) return;
            const response = await PublicService.getBookingLink(url);
            if (response.status === 'success') {
                setCompanyName(
                    response.data?.booking_link.metadata.business_name ||
                        'SimplyWise'
                );
            }
        };
        fetchBookingLink();
    }, []);

    useEffect(() => {
        const fetchFormId = async () => {
            const response =
                await PublicService.getCostEstimatorQuestionaireFormId(
                    url ?? ''
                );
            if (response.status === 'success') {
                const fetchedFormId = response.data.booking_link_form.id;
                setFormId(fetchedFormId);
            }
        };
        fetchFormId();
    }, []);

    const resizeFile = (file: File): Promise<Blob> => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1024,
                1024,
                'JPEG',
                85,
                0,
                //@ts-ignore
                (b: Blob) => {
                    resolve(b);
                },
                'blob'
            );
        });
    };

    const onUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _file = event?.target?.files?.[0];
        if (!_file) return;
        if (_file.type !== 'image/jpeg') return;

        resizeFile(_file).then((resizedFile) => {
            const imageUrl = URL.createObjectURL(resizedFile);
            setImageUri(imageUrl);
            PublicService.uploadImage(resizedFile)
                .then(async (res) => {
                    setImageIds([res.data.image_ids[0]]);
                    await submitProgressToForm({
                        image_ids: [res.data.image_ids[0]],
                    });
                    onWebAppCostEstimationBookingLinkStep({
                        step: 'take-photo',
                        form_id: formId || 0,
                        metadata: {
                            image_ids: [res.data.image_ids[0]],
                        },
                    });
                    setStep('project-description');
                })
                .catch((err) => {
                    console.error('Upload error:', err);
                });
        });
    };

    return (
        <QuestionaireContainer>
            <input
                type="file"
                accept="image/*"
                ref={imageInputRef}
                onChange={onUploadImage}
                style={{ display: 'none' }}
            />
            <QuestionaireHeader title={companyName} />
            <ContentContainer>
                {step === 'start' ? (
                    <StepStart url={url} />
                ) : step === 'take-photo' ? (
                    <StepTakePhoto imageInputRef={imageInputRef} />
                ) : step === 'project-description' ? (
                    <StepDescription />
                ) : step === 'step-questions' ? (
                    <StepQuestions />
                ) : step === 'client-info-step' ? (
                    <StepClientInfo />
                ) : (
                    <StepDone />
                )}
            </ContentContainer>
        </QuestionaireContainer>
    );
};

export default CostEstimatorQuestionaire;
