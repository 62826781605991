import { track, flush } from '@amplitude/analytics-browser';
import LogRocket from 'logrocket';
import { ENV } from '../configuration';
import { IScheduledState } from '../services/receipts/types';
import MiscService from '../services/misc';
import * as EventPayload from './eventTypes';

const trackExternal = (event: any, eventParams?: any) => {
    if (ENV === 'production') {
        return new Promise((resolve, reject) => {
            const trackWithRetry = (
                event: any,
                eventParams?: any,
                tries?: number
            ) => {
                // Add user metadata to the event
                eventParams = eventParams || {};
                eventParams.user_metadata = JSON.parse(
                    localStorage.getItem('companyInfo') || '{}'
                );
                // Amplitude track
                track(event, eventParams)
                    .promise.then(() => {
                        // console.log(`${event} sent successfully`);
                        resolve(`${event} sent successfully`);
                        // Send all Amplitude events to LogRocket
                        LogRocket.track(event, eventParams);
                    })
                    .catch(() => {
                        if (!tries || tries < 10)
                            setTimeout(
                                () =>
                                    trackWithRetry(
                                        event,
                                        eventParams,
                                        (tries || 0) + 1
                                    ),
                                250
                            );

                        console.error(
                            `Event failed: ${event}, retrying ${tries || 0}`
                        );
                        reject(
                            `Event failed: ${event}, retrying ${tries || 0}`
                        );
                    });
            };
            trackWithRetry(event, eventParams);
        });
    } else {
        const message = `Logging event: ${event}, payload: ${JSON.stringify(
            eventParams
        )}`;
        console.log(message);
    }
};

export const flushExternal = () => {
    if (ENV === 'production') {
        return new Promise((resolve, reject) => {
            flush()
                .promise.then(() => {
                    console.log('Amplitude events flushed successfully');
                    resolve('Amplitude events flushed successfully');
                })
                .catch(() => {
                    console.error('Amplitude events failed to flush');
                    reject('Amplitude events failed to flush');
                });
        });
    }
};

const trackExternalBackend = (event: any, eventParams?: any) => {
    MiscService.submitTrackingEvent(event, eventParams);
};

export const onWebAppLandingPageCTAClick = (
    props: EventPayload.onWebAppLandingPageCTAClickProps
) => {
    trackExternal('onWebAppLandingPageCTAClick', { ...props });
};

export const onWebAppViewFolder = (
    props: EventPayload.onWebAppViewFolderProps
) => {
    trackExternal('onWebAppViewFolder', { ...props });
};

export const onWebAppViewReceipt = (
    props: EventPayload.onWebAppViewReceiptProps
) => {
    trackExternal('onWebAppViewReceipt', { ...props });
};

export const onWebAppLogin = (props: EventPayload.onWebAppLoginProps) => {
    trackExternal('onWebAppLogin', { ...props });
};

export const onWebAppLoginFailure = (
    props: EventPayload.onWebAppLoginFailureProps
) => {
    trackExternal('onWebAppLoginFailure', { ...props });
};

export const onWebAppScreenChange = (
    props: EventPayload.onWebAppScreenChangeProps
) => {
    trackExternal('onWebAppScreenChange', { ...props });
};

// EXPORT

export const onWebAppExportSpreadsheet = () => {
    trackExternal('onWebAppExportSpreadsheet', {});
};

export const onWebAppExportSelectDate = (
    props: EventPayload.onWebAppExportSelectDateProps
) => {
    trackExternal('onWebAppExportSelectDate', { ...props });
};

export const onWebAppClickExport = (
    props: EventPayload.onWebAppClickExportProps
) => {
    trackExternal('onWebAppClickExport', { ...props });
};

// ONBOARDING EVENTS

export const onWebAppOnboardingLoad = (
    props: EventPayload.onWebAppOnboardingLoadProps
) => {
    trackExternal('onWebAppOnboardingLoad', { ...props });
};

export const onWebAppOnboardingSubmitAnswer = (
    props: EventPayload.onWebAppOnboardingSubmitAnswerProps
) => {
    trackExternal('onWebAppOnboardingSubmitAnswer', { ...props });
};

export const onWebAppOnboardingGoNext = (
    props: EventPayload.onWebAppOnboardingGoNextProps
) => {
    trackExternal('onWebAppOnboardingGoNext', { ...props });
};

export const onWebAppOnboardingGoBack = (
    props: EventPayload.onWebAppOnboardingGoNextProps
) => {
    trackExternal('onWebAppOnboardingGoBack', { ...props });
};

export const onWebAppLoadSignup = () => {
    trackExternal('onWebAppLoadSignup', {});
};

export const onWebAppSubmitMobile = (
    props: EventPayload.onWebAppSubmitMobileProps
) => {
    trackExternal('onWebAppSubmitMobile', { ...props });
};

export const onWebAppRegisterSuccess = (
    props: EventPayload.onWebAppSubmitMobileProps
) => {
    trackExternal('onWebAppRegisterSuccess', { ...props });
};

export const onWebAppRegisterFailure = (
    props: EventPayload.onWebAppSubmitMobileProps
) => {
    trackExternal('onWebAppRegisterFailure', { ...props });
};

export const onWebAppRegisterAttempt = (
    props: EventPayload.onWebAppRegisterAttemptProps
) => {
    trackExternal('onWebAppRegisterAttempt', { ...props });
};

export const onWebAppSendCodeSuccess = (
    props: EventPayload.onWebAppSendCodeProps
) => {
    trackExternal('onWebAppSendCodeSuccess', { ...props });
};

export const onWebAppSendCodeFailure = (
    props: EventPayload.onWebAppSendCodeProps
) => {
    trackExternal('onWebAppSendCodeFailure', { ...props });
};

export const onWebAppSubmitCodeSuccess = (
    props: EventPayload.onWebAppSubmitMobileProps
) => {
    trackExternal('onWebAppSubmitCodeSuccess', { ...props });
};

export const onWebAppSubmitCodeFailure = (
    props: EventPayload.onWebAppSubmitMobileProps
) => {
    trackExternal('onWebAppSubmitCodeFailure', { ...props });
};

export const onWebAppSubmitPasswordSuccess = (
    props: EventPayload.onWebAppSubmitMobileProps
) => {
    trackExternal('onWebAppSubmitPasswordSuccess', { ...props });
};

export const onWebAppSubmitPasswordFailure = (
    props: EventPayload.onWebAppSubmitMobileProps
) => {
    trackExternal('onWebAppSubmitPasswordFailure', { ...props });
};

export const onWebAppSelectBaseTier = () => {
    trackExternal('onWebAppSelectBaseTier', {});
};

export const onWebAppSelectProTier = () => {
    trackExternal('onWebAppSelectProTier', {});
};

export const onWebAppConfirmPaymentTier = (
    props: EventPayload.onWebAppConfirmPaymentTierProps
) => {
    trackExternal('onWebAppConfirmPaymentTier', { ...props });
};

export const onWebAppPaymentSessionSuccess = (
    props: EventPayload.onWebAppConfirmPaymentTierProps
) => {
    trackExternal('onWebAppPaymentSessionSuccess', { ...props });
};

export const onWebAppPaymentSessionFailure = (
    props: EventPayload.onWebAppConfirmPaymentTierProps
) => {
    trackExternal('onWebAppPaymentSessionFailure', { ...props });
};

export const onWebAppLoadPaymentSuccessScreen = (
    props: EventPayload.onWebAppLoadPaymentSuccessScreenProps
) => {
    trackExternal('onWebAppLoadPaymentSuccessScreen', { ...props });
};

export const onWebAppLoadPaymentCancelScreen = () => {
    trackExternal('onWebAppLoadPaymentSuccessScreen', {});
};

export const onWebAppVisitAppStore = (
    props: EventPayload.onWebAppVisitAppStoreProps
) => {
    trackExternal('onWebAppVisitAppStore', { ...props });
};

export const onWebAppUploadImageFailure = (
    props: EventPayload.onWebAppUploadImageFailureProps
) => {
    trackExternal('onWebAppUploadImageFailure', { ...props });
};

export const onWebAppUploadImageSuccess = (
    props: EventPayload.onWebAppUploadImageSuccessProps
) => {
    trackExternal('onWebAppUploadImageSuccess', { ...props });
};

export const onWebAppUploadDropzoneDocument = (props: {
    file_type: string;
}) => {
    trackExternal('onWebAppUploadDropzoneDocument', props);
};

export const onWebAppClickTakePicture = (
    props: EventPayload.onWebAppClickTakePictureProps
) => {
    trackExternal('onWebAppClickTakePicture', { ...props });
};

export const onWebAppPdfImageIncomplete = (
    props: EventPayload.onWebAppPdfImageIncompleteProps
) => {
    trackExternal('onWebAppPdfImageIncomplete', { ...props });
};

export const onWebAppOnboardingDownloadApp = (
    props: EventPayload.onWebAppOnboardingDownloadAppProps
) => {
    trackExternal('onWebAppOnboardingDownloadApp', { ...props });
};

export const onWebAppOnboardingLoadDownload = () => {
    trackExternal('onWebAppOnboardingLoadDownload', {});
};

export const onWebAppRequestDownloadLink = (
    props: EventPayload.onWebAppRequestDownloadLinkProps
) => {
    trackExternal('onWebAppRequestDownloadLink', { ...props });
};

export const onWebAppOnboardingCompleted = (
    props: EventPayload.onWebAppOnboardingCompletedProps
) => {
    trackExternal('onWebAppOnboardingCompleted', { ...props });
};

export const onWebAppGoToAccountCreation = (
    props: EventPayload.onWebAppGoToAccountCreationProps
) => {
    trackExternal('onWebAppGoToAccountCreation', { ...props });
};

export const onWebAppRetakePicture = () => {
    trackExternal('onWebAppRetakePicture', {});
};

export const onWebAppUploadDocument = () => {
    trackExternal('onWebAppUploadDocument', {});
};

export const onWebAppTapDetailsField = (
    props: EventPayload.onWebAppTapDetailsFieldProps
) => {
    trackExternal('onWebAppTapDetailsField', { ...props });
};

export const onWebAppConfirmDetails = () => {
    trackExternal('onWebAppConfirmDetails', {});
};

export const onWebAppParsingSuccess = (
    props: EventPayload.onWebAppParsingProps
) => {
    trackExternal('onWebAppParsingSuccess', { ...props });
};

export const onWebAppParsingFailure = (
    props: EventPayload.onWebAppParsingProps
) => {
    trackExternal('onWebAppParsingFailure', { ...props });
};

export const onWebAppGetProcessingReceiptSuccess = (
    props: EventPayload.onWebAppParsingProps
) => {
    trackExternal('onWebAppGetProcessingReceiptSuccess', { ...props });
};

export const onWebAppGetProcessingReceiptFailure = (
    props: EventPayload.onWebAppParsingProps
) => {
    trackExternal('onWebAppGetProcessingReceiptFailure', { ...props });
};

export const onWebAppLoadCameraPage = () => {
    trackExternal('onWebAppLoadCameraPage', {});
};

export const onWebAppLoadLandingPage = (
    props: EventPayload.onWebAppLoadLandingPageProps
) => {
    trackExternal('onWebAppLoadLandingPage', { ...props });
};

export const onWebAppLogIsIos = (props: EventPayload.onWebAppLogIsIosProps) => {
    trackExternal('onWebAppLogIsIos', { ...props });
};

export const onWebAppDemoTapElement = (
    props: EventPayload.onWebAppDemoTapElementProps
) => {
    trackExternal('onWebAppDemoTapElement', { ...props });
};

export const onWebAppDemoNavigate = (
    props: EventPayload.onWebAppDemoNavigateProps
) => {
    trackExternal('onWebAppDemoNavigate', { ...props });
};

export const onWebAppOpenFromReferral = (
    props: EventPayload.onWebAppOpenFromReferral
) => {
    trackExternal('onWebAppOpenFromReferral', { ...props });
};

// Events from Stripe Billing

export const onWebAppLoadPaymentScreen = (
    props: EventPayload.onWebAppLoadPaymentScreenProps
) => {
    trackExternal('onWebAppLoadPaymentScreen', { ...props });
};

export const onWebAppCreateSubscriptionAttempt = (
    props: EventPayload.onWebAppCreateSubscriptionAttemptProps
) => {
    trackExternal('onWebAppCreateSubscriptionAttempt', { ...props });
};

export const onWebAppPaymentAttempt = (
    props: EventPayload.onWebAppPaymentAttemptProps
) => {
    trackExternal('onWebAppPaymentAttempt', { ...props });
};

export const onWebAppCostEstimatorLoadPhoneScreen = (props: {
    experiment?: string | null;
    cohort?: string | null;
}) => {
    trackExternal('onWebAppCostEstimatorLoadPhoneScreen', { ...props });
};

export const onWebAppCostEstimatorRegisterPhoneless = (props: {
    experiment?: string | null;
    cohort?: string | null;
}) => {
    trackExternal('onWebAppCostEstimatorRegisterPhoneless', { ...props });
};

export const onWebAppCostEstimatorLoadCodeScreen = (props: {
    mobile?: string;
    experiment?: string | null;
    cohort?: string | null;
}) => {
    trackExternal('onWebAppCostEstimatorLoadCodeScreen', { ...props });
};

export const onWebAppInitiatePayment = (
    props: EventPayload.onWebAppInitiatePaymentProps
) => {
    trackExternal('onWebAppInitiatePayment', { ...props });
};

export const onWebAppApplePayAvailabilityStatus = (
    props: EventPayload.onWebAppApplePayAvailabilityStatusProps
) => {
    trackExternal('onWebAppApplePayAvailabilityStatus', { ...props });
};

export const onWebAppCanMakePayment = (
    props: EventPayload.onWebAppCanMakePaymentProps
) => {
    trackExternal('onWebAppCanMakePayment', { ...props });
};

export const onWebAppLoadPaymentPreviewScreen = (
    props: EventPayload.onWebAppLoadPaymentPreviewScreenProps
) => {
    trackExternal('onWebAppLoadPaymentPreviewScreen', { ...props });
};

export const onWebAppLoadDownloadPlaystoreScreen = () => {
    trackExternal('onWebAppLoadDownloadPlaystoreScreen', {});
};

export const onWebAppApplyCoupon = (
    props: EventPayload.onWebAppApplyCouponProps
) => {
    trackExternal('onWebAppApplyCoupon', { ...props });
};

export const onWebAppApplyCouponFailure = () => {
    trackExternal('onWebAppApplyCouponFailure', {});
};

export const onWebAppLoadScanReceipt = () => {
    trackExternal('onWebAppLoadScanReceipt', {});
};

// Events from Acquisitions

export const onWebAppScanQrCode = (
    props: EventPayload.onWebAppScanQrCodeProps
) => {
    trackExternal('onWebAppScanQrCode', { ...props });
};

export const onWebAppNavigateQrScan = (
    props: EventPayload.onWebAppNavigateQrScanProps
) => {
    trackExternal('onWebAppNavigateQrScan', { ...props });
};

export const onWebAppUploadPdfImage = (
    props: EventPayload.onWebAppUploadPdfImageProps
) => {
    trackExternal('onWebAppUploadPdfImage', { ...props });
};

export const onWebAppPdfDownloadCta = (
    props: EventPayload.onWebAppPdfDownloadCtaProps
) => {
    trackExternal('onWebAppPdfDownloadCta', { ...props });
};

export const onWebAppPdfAcceptBenefits = (
    props: EventPayload.onWebAppPdfAcceptBenefitsProps
) => {
    trackExternal('onWebAppPdfAcceptBenefits', { ...props });
};

export const onWebAppPdfAttemptPayment = (
    props: EventPayload.onWebAppPdfAttemptPaymentProps
) => {
    trackExternal('onWebAppPdfAttemptPayment', { ...props });
};

export const onWebAppSharePDF = (props: { pdfId: string; url: string }) => {
    trackExternal('onWebAppSharePDF', { ...props });
};

export const onWebAppSharePDFClickCTA = (props: { button: string }) => {
    trackExternal('onWebAppSharePDFClickCTA', { ...props });
};

export const onWebAppDownloadPDF = (props: {
    source?: 'details' | 'report';
}) => {
    trackExternal('onWebAppDownloadPDF', props);
};

export const onWebAppSharePDFClickToShare = (props: {
    pdfId: string;
    url: string;
}) => {
    trackExternal('onWebAppSharePDFClickToShare', { ...props });
};

export const onWebAppPDFRedirect = () => {
    trackExternal('onWebAppPDFRedirect');
};

export const onWebSelectNylasClient = (props: { platform: string }) => {
    trackExternal('onWebSelectNylasClient', { ...props });
};

export const onWebAppViewReport = (
    props: EventPayload.onWebAppViewReportProps
) => {
    // trackExternal('onWebAppViewReport', { ...props });
    trackExternalBackend('onWebAppViewReport', { ...props });
};

export const onWebAppViewReportPinPage = (
    props: EventPayload.onWebAppViewReportProps
) => {
    trackExternal('onWebAppViewReportPinPage', { ...props });
};

export const onWebAppViewReportErrorPage = (
    props: EventPayload.onWebAppViewReportProps
) => {
    trackExternal('onWebAppViewReportErrorPage', { ...props });
};

export const onWebAppSubmitReportPinSuccess = (
    props: EventPayload.onWebAppReportPinProps
) => {
    trackExternal('onWebAppSubmitReportPinSuccess', { ...props });
};

export const onWebAppSubmitReportPinFailure = (
    props: EventPayload.onWebAppReportPinProps
) => {
    trackExternal('onWebAppSubmitReportPinFailure', { ...props });
};

export const onWebAppViewReportImage = (
    props: EventPayload.onWebAppReportImageProps
) => {
    trackExternal('onWebViewReportImage', { ...props });
};

export const onWebAppDownloadReport = (
    props: EventPayload.onWebAppDownloadReportProps
) => {
    trackExternal('onWebAppDownloadReport', { ...props });
};

export const onWebAppDownloadImages = (
    props: EventPayload.onWebAppDownloadImageProps
) => {
    trackExternal('onWebAppDownloadImages', props);
};

export const onWebAppDownloadImagesComplete = (props: {
    status: 'success' | 'error';
    file_type: string;
    timeToComplete?: number;
}) => {
    trackExternal('onWebAppDownloadImagesComplete', props);
};

export const onWebAppDownloadReportCompleted = (
    props: EventPayload.onWebAppDownloadReportProps
) => {
    trackExternal('onWebAppDownloadReportCompleted', { ...props });
};

export const onWebAppCreateReport = (
    props: EventPayload.onWebAppCreateReportProps
) => {
    trackExternal('onWebAppCreateReport', { ...props });
};

export const onWebAppCreateReportFailure = (
    props: EventPayload.onWebAppCreateReportProps
) => {
    trackExternal('onWebAppCreateReportFailure', { ...props });
};

export const onWebAppReportInteraction = (
    props: EventPayload.onWebAppReportInteractionProps
) => {
    trackExternal('onWebAppReportInteraction', { ...props });
};

export const onWebClickValuePropButton = (props: { valueProp: string }) => {
    trackExternal('onWebClickValuePropButton', { ...props });
};

export const onWebAppUploadJpg = () => {
    trackExternal('onWebAppUploadJpg');
};

export const onWebAppLoadSelectJPGUploadType = () => {
    trackExternal('onWebAppLoadSelectJPGUploadType');
};

export const onWebAppDropDocuments = (props: { count: number }) => {
    trackExternal('onWebAppDropDocuments', { ...props });
};

export const onWebAppRejectDroppedDocument = (props: { format: string }) => {
    trackExternal('onWebAppRejectDroppedDocument', { ...props });
};

export const onWebAppRemoveDropzoneDocument = () => {
    trackExternal('onWebAppRemoveDropzoneDocument', {});
};

export const onWebAppUploadDropzoneDocuments = (props: { count: number }) => {
    trackExternal('onWebAppUploadDropzoneDocuments', { ...props });
};

export const onWebAppUploadCategorize = (props: {
    categoryNames: string[];
}) => {
    trackExternal('onWebAppUploadCategorize', { ...props });
};

export const onWebAppUploadCommitSuccess = () => {
    trackExternal('onWebAppUploadCommitSuccess', {});
};

export const onWebAppUploadCommitFailure = () => {
    trackExternal('onWebAppUploadCommitFailure', {});
};

export const onWebAppCloseDropzoneModal = () => {
    track('onWebAppCloseDropzoneModal', {});
};

export const onWebAppSubmitTaxLocation = (props: { zipCode: string }) => {
    trackExternal('onWebAppSubmitTaxLocation', { ...props });
};

export const onWebAppClickTaxSignup = () => {
    trackExternal('onWebAppClickTaxSignup');
};

export const onWebAppOnboardingConnectGmail = () => {
    trackExternal('onWebAppOnboardingConnectGmail');
};

export const onWebAppOnboardingSkipConnectGmail = () => {
    trackExternal('onWebAppOnboardingSkipConnectGmail');
};

export const onWebAppOnboardingSkipTakePhoto = () => {
    trackExternal('onWebAppOnboardingSkipTakePhoto');
};

export const onWebAppLoadOauthConnectPrompt = () => {
    trackExternal('onWebAppLoadOauthConnectPrompt');
};

export const onWebAppOnboardingOriginWebUrlFound = () => {
    trackExternal('onWebAppOnboardingOriginWebUrlFound');
};

export const onWebAppOnboardingGmailTokenFound = () => {
    trackExternal('onWebAppOnboardingGmailTokenFound');
};

export const onWebAppOnboardingGmailErrorFound = () => {
    trackExternal('onWebAppOnboardingGmailErrorFound');
};

export const onWebAppLoadAccountSetupComplete = () => {
    trackExternal('onWebAppLoadAccountSetupComplete');
};

export const onWebAppOnboardingEmailsFound = (props: {
    emailCount?: number;
    email: string | null;
    import_state?: string;
}) => {
    trackExternal('onWebAppOnboardingEmailsFound', { ...props });
};

export const onWebAppOnboardingOnTheFlyEmailFound = (props: {
    emailCount?: number;
    email: string | null;
}) => {
    trackExternal('onWebAppOnboardingOnTheFlyEmailFound', {
        email_count: props.emailCount,
        email: props.email,
    });
};

export const onWebAppOnboardingCommitEReceiptsSuccess = () => {
    trackExternal('onWebAppOnboardingCommitEReceiptsSuccess');
};

export const onWebAppOnboardingCommitEReceiptsFailure = () => {
    trackExternal('onWebAppOnboardingCommitEReceiptsFailure');
};

export const onWebAppOnboardingPopupOpened = () => {
    trackExternal('onWebAppOnboardingPopupOpened');
};

export const onWebAppOnboardingOpenReport = () => {
    trackExternal('onWebAppOnboardingOpenReport');
};

export const onWebAppOnboardingOpenReportFailure = () => {
    trackExternal('onWebAppOnboardingOpenReportFailure');
};

export const onWebAppOnboardingReportClickNext = () => {
    trackExternal('onWebAppOnboardingReportClickNext');
};

export const onWebAppLoadOnboardingAfterReport = (props: {
    showInfoBeforePayment?: boolean;
}) => {
    trackExternal('onWebAppLoadOnboardingAfterReport', props);
};

export const onWebAppLoadFolderQuiz = () => {
    trackExternal('onWebAppLoadFolderQuiz');
};

export const onWebAppLoadReportReady = () => {
    trackExternal('onWebAppLoadReportReady');
};

export const onWebAppLoadParseEmails = () => {
    trackExternal('onWebAppLoadParseEmails');
};

export const onWebAppLoadReportPreview = () => {
    trackExternal('onWebAppLoadReportPreview');
};

export const onWebAppOnboardingComplete = (props: {
    emailConnected?: boolean;
}) => {
    trackExternal('onWebAppOnboardingComplete', props);
};

export const onWebAppReferralPaymentLandingLoad = () => {
    trackExternal('onWebAppReferralPaymentLandingLoad');
};

export const onWebAppSignupUrlRequested = (props: { mobile: string }) => {
    trackExternal('onWebAppSignupUrlRequested', { ...props });
};

export const onWebAppSignupUrlSent = (props: {
    mobile: string;
    origin_web_url?: string | null;
}) => {
    trackExternal('onWebAppSignupUrlSent', { ...props });
};

export const onWebAppSignupUrlAccessed = (props: {
    mobile: string;
    origin_web_url?: string | null;
}) => {
    trackExternal('onWebAppSignupUrlAccessed', { ...props });
};

export const onWebAppFacebookSignupUrlAccessed = (props: {
    mobile: string;
}) => {
    trackExternal('onWebAppFacebookSignupUrlAccessed', { ...props });
};

export const onWebAppUserTokenFound = (props: { user_id: number }) => {
    trackExternal('onWebAppUserTokenFound', { ...props });
};

export const onWebAppAnswerSalesTaxBot = (props: { answer: string }) => {
    trackExternal('onWebAppAnswerSalesTaxBot', { ...props });
};

export const onWebAppViewUsageSurvey = (props: { cohort?: string }) => {
    trackExternal('onWebAppViewUsageSurvey', { ...props });
};

export const onWebAppUserDidConnectEmail = () => {
    trackExternal('onWebAppUserDidConnectEmail', {});
};

export const onWebAppParseEmailsState = (props: { importState: string }) => {
    trackExternal('onWebAppParseEmailsState', props);
};

export const onWebAppAnswerUsageSurvey = (props: {
    answer: string;
    cohort?: string;
}) => {
    trackExternal('onWebAppAnswerUsageSurvey', { ...props });
};

export const onWebAppToggleTaxAddOn = (props: {
    value: boolean;
    cohort?: string;
}) => {
    trackExternal('onWebAppToggleTaxAddOn', { ...props });
};

export const onWebAppToggleAllPlans = (props: {
    value: boolean;
    cohort?: string;
}) => {
    trackExternal('onWebAppToggleAllPlans', { ...props });
};

export const onWebAppOnboardingLoadForwardReceipts = () => {
    trackExternal('onWebAppOnboardingLoadForwardReceipts');
};

export const onWebAppLoadReportFlow = (props: { isDemo: boolean }) => {
    trackExternal('onWebAppLoadReportFlow', { ...props });
};

export const onWebAppClickFreemiumX = () => {
    trackExternal('onWebAppClickFreemiumX', {});
};

export const onWebAppContinueWithFreeTier = () => {
    trackExternal('onWebAppContinueWithFreeTier', {});
};

export const onWebAppLoadIntroFlow = () => {
    trackExternal('onWebAppLoadIntroFlow', {});
};

export const onWebAppInvoiceViewLanding = () => {
    trackExternal('onWebAppInvoiceViewLanding', {});
};

export const onWebAppInvoiceCreateClick = () => {
    trackExternal('onWebAppInvoiceCreateClick', {});
};

export const onWebAppInvoiceDownload = (props: {
    name: string;
    phone: string;
    client_name: string;
    client_email: string;
}) => {
    trackExternal('onWebAppInvoiceDownload', { ...props });
};

export const onWebAppSearch = (props: {
    source: string;
    folder: string;
    query: string;
}) => {
    trackExternal('onWebAppSearch', props);
};

export const onWebAppSelectFromSearch = (props: {
    source: string;
    folder: string;
    query: string;
}) => {
    trackExternal('onWebAppSelectFromSearch', props);
};

export const onWebAppSelectIcon = (props: {
    name: string;
    isFromSearch: boolean;
}) => {
    trackExternal('onWebAppSelectIcon', props);
};

export const onWebAppCreateFolder = (props: {
    name: string;
    iconId: string;
}) => {
    trackExternal('onWebAppCreateFolder', props);
};

export const onWebAppClickDeleteFolder = (props: {
    folderId: string;
    name: string;
}) => {
    trackExternal('onWebAppClickDeleteFolder', props);
};

export const onWebAppDeleteFolder = (props: {
    folderId: string;
    name: string;
}) => {
    trackExternal('onWebAppDeleteFolder', props);
};

export const onWebAppRemoveFolderFromGroup = (props: {
    folderId: string;
    folderGroupId: number;
}) => {
    trackExternal('onWebAppRemoveFolderFromGroup', props);
};

export const onWebAppClickFolderEdit = () => {
    trackExternal('onWebAppClickFolderEdit', {});
};

export const onWebAppClickAddFolder = () => {
    trackExternal('onWebAppClickAddFolder', {});
};

export const onWebAppClickSpaceName = () => {
    trackExternal('onWebAppClickSpaceName', {});
};

export const onWebAppEditFolder = (props: {
    id: string;
    name: string;
    iconId: string;
}) => {
    trackExternal('onWebAppEditFolder', props);
};

export const onWebAppClickFolderDuringEdit = (props: {
    folderId: string;
    name: string;
    iconId: string;
}) => {
    trackExternal('onWebAppClickFolderDuringEdit', props);
};

export const onWebAppAnswerPreSignupSurvey = (props: {
    question: string;
    answer: string;
}) => {
    trackExternal('onWebAppAnswerPreSignupSurvey', props);
};

export const onWebAppLoadPreSignupSurvey = () => {
    trackExternal('onWebAppLoadPreSignupSurvey', {});
};

export const onWebAppPasswordResetFailure = () => {
    trackExternal('onWebAppPasswordResetFailure', {});
};

export const onWebAppVerifyCodeFailure = () => {
    trackExternal('onWebAppVerifyCodeFailure', {});
};

export const onWebAppSubmitNewPasswordFailure = () => {
    trackExternal('onWebAppSubmitNewPasswordFailure', {});
};

export const onWebAppClickForgotPassword = () => {
    trackExternal('onWebAppClickForgotPassword', {});
};

export const onWebAppLoginClickSignup = (props: { formerPath: string }) => {
    trackExternal('onWebAppLoginClickSignup', props);
};

export const onWebAppEditReceipt = (
    props: EventPayload.onWebAppEditReceiptProps
) => {
    trackExternal('onWebAppEditReceipt', props);
};

export const onWebAppEditLineItem = (
    props: EventPayload.onWebAppEditLineItemProps
) => {
    trackExternal('onWebAppEditLineItem', props);
};

export const onWebAppLogOut = () => {
    trackExternal('onWebAppLogOut', {});
};

export const onWebAppDownloadImage = (
    props: EventPayload.onWebAppDownloadImageProps
) => {
    trackExternal('onWebAppDownloadImage', props);
};

export const onWebAppSalesTaxCalculatorView = (props: {
    visitor_id: string;
    exp_name: string;
}) => {
    trackExternal('onWebAppSalesTaxCalculatorView', props);
};

export const onWebAppSalesTaxCalculatorData = (props: {
    amount?: number;
    state?: string;
}) => {
    trackExternal('onWebAppSalesTaxCalculatorData', props);
};

export const onWebAppSalesTaxCalculatorSurvery = (props: {
    question: string;
    answer?: string;
}) => {
    trackExternal('onWebAppSalesTaxCalculatorSurvery', props);
};

export const onWebAppSalesTaxCalculatorResult = () => {
    trackExternal('onWebAppSalesTaxCalculatorResult', {});
};

export const onWebAppSalesTaxCalculatorTryApp = ({
    loading,
}: {
    loading?: boolean;
}) => {
    trackExternal('onWebAppSalesTaxCalculatorTryApp', {
        loading,
    });
};

export const onWebAppSalesTaxCalculatorConnectEmail = () => {
    trackExternal('onWebAppSalesTaxCalculatorConnectEmail', {});
};

export const onWebAppSalesTaxCalculatorGetReport = () => {
    trackExternal('onWebAppSalesTaxCalculatorGetReport', {});
};

export const onWebAppUpdateReportSchedule = (props: {
    field: keyof IScheduledState;
    value: string | boolean;
}) => {
    trackExternal('onWebAppUpdateReportSchedule', props);
};

export const onWebAppLoadHomeScreen = () => {
    trackExternal('onWebAppLoadHomeScreen', {});
};

export const onWebAppClickUncategorizedTab = (props: { count?: number }) => {
    trackExternal('onWebAppClickUncategorizedTab', props);
};

export const onWebAppSaveUncategorizedReceipt = (props: {
    receiptId: string;
}) => {
    trackExternal('onWebAppSaveUncategorizedReceipt', props);
};

export const onWebAppOnboardingLoadUrlRedirect = ({ url }: { url: string }) => {
    trackExternal('onWebAppOnboardingLoadUrlRedirect', {
        url,
    });
};

export const onWebAppCreateSpace = (props: { name?: string }) => {
    trackExternal('onWebAppCreateSpace', { ...props });
};

export const onWebAppAssignSpace = (props: { name?: string }) => {
    trackExternal('onWebAppAssignSpace', { ...props });
};

export const onWebAppUnassignSpace = (props: { name?: string }) => {
    trackExternal('onWebAppUnassignSpace', { ...props });
};

export const onWebAppEditSpace = (props: { name?: string }) => {
    trackExternal('onWebAppEditSpace', { ...props });
};

export const onWebAppDeleteSpace = (props: { id?: number }) => {
    trackExternal('onWebAppDeleteSpace', { ...props });
};

export const onWebAppClickAddSpace = () => {
    trackExternal('onWebAppClickAddSpace', {});
};

export const onWebAppDuplicateSpace = (props: { name?: string }) => {
    trackExternal('onWebAppDuplicateSpace', { ...props });
};

export const onWebAppClickEditSpaceIcon = (props: {
    tagId: string;
    groupOrTag: 'tag' | 'group';
}) => {
    trackExternal('onWebAppClickEditSpaceIcon', props);
};

export const onWebAppClickDeleteSpace = (props: {
    id: string;
    name: string;
}) => {
    trackExternal('onWebAppClickDeleteSpace', props);
};

export const onWebAppDynamicLinkLoaded = (props: {
    link_token?: string;
    status?: string;
    error?: string;
    link_type?: string;
    user?: {
        id?: number;
        mobile?: string;
    };
    metadata?: any;
}) => {
    trackExternal('onWebAppDynamicLinkLoaded', props);
};

export const onWebAppShareLinkLoaded = (props: {
    share_token?: string;
    link_type?: string;
    status?: string;
    error?: string;
    user?: {
        id?: number;
        mobile?: string;
    };
    categories?: string[];
    metadata?: any;
    cohort?: string;
}) => {
    trackExternal('onWebAppShareLinkLoaded', props);
};

export const onWebAppMagicAuthLinkLoaded = (props: {
    magic_auth_token?: string;
    link_type?: string;
    status?: string;
    error?: string;
    user?: {
        id?: number;
        mobile?: string;
    };
    categories?: string[];
    metadata?: any;
    cohort?: string;
}) => {
    trackExternal('onWebAppMagicAuthLinkLoaded', props);
};

export const onWebAppShareAcceptClicked = ({
    share_token,
}: {
    share_token?: string;
}) => {
    trackExternal('onWebAppShareAcceptClicked', {
        share_token,
    });
};

export const onWebAppOrganizationAcceptClicked = ({
    organization_token,
}: {
    organization_token?: string;
}) => {
    trackExternal('onWebAppOrganizationAcceptClicked', {
        organization_token,
    });
};
export const onWebAppShareContinueInAppClicked = ({
    share_token,
}: {
    share_token?: string;
}) => {
    trackExternal('onWebAppShareContinueInAppClicked', {
        share_token,
    });
};

export const onWebAppReferralLinkLoaded = (props: {
    referral_token?: string;
    cohort?: string;
}) => {
    trackExternal('onWebAppReferralLinkLoaded', props);
};

export const onWebAppSpreadsheetSaveSuccess = (
    props: EventPayload.onWebAppSpreadsheetSaveProps
) => {
    trackExternal('onWebAppSpreadsheetSaveSuccess', props);
};

export const onWebAppSpreadsheetSaveFailure = (
    props: EventPayload.onWebAppSpreadsheetSaveProps
) => {
    trackExternal('onWebAppSpreadsheetSaveFailure', props);
};

export const onWebAppSpreadsheetUndoSuccess = (
    props: EventPayload.onWebAppSpreadsheetSaveProps
) => {
    trackExternal('onWebAppSpreadsheetUndoSuccess', props);
};

export const onWebAppSpreadsheetUndoFailure = (
    props: EventPayload.onWebAppSpreadsheetSaveProps
) => {
    trackExternal('onWebAppSpreadsheetUndoFailure', props);
};

export const onWebAppSpreadsheetClickFullDocumentLink = (props: {
    receiptId: string;
}) => {
    trackExternal('onWebAppSpreadsheetClickFullDocumentLink', props);
};

export const onWebAppSignupSuggestedMobileDetected = (props: {
    mobile?: string;
}) => {
    trackExternal('onWebAppSignupSuggestedMobileDetected', props);
};

export const onWebAppSignupSuggestedMobileFailed = (props: {
    mobile?: string;
}) => {
    trackExternal('onWebAppSignupSuggestedMobileFailed', props);
};

export const onWebAppViewOrganizationLandingPage = (props: { url: string }) => {
    trackExternal('onWebAppViewOrganizationLandingPage', props);
};

export const onWebAppViewOrganizationTypeformSuccess = () => {
    trackExternal('onWebAppViewOrganizationTypeformSuccess');
};

export const onWebAppAddOrganizationTeamMember = ({
    name,
    email,
}: {
    name?: string;
    email?: string;
}) => {
    trackExternal('onWebAppAddOrganizationTeamMember', { name, email });
};

export const onWebAppViewOrganizationPaymentPage = (props: {
    source?: string | null;
}) => {
    trackExternal('onWebAppViewOrganizationPaymentPage', props);
};

export const onWebAppAttempOrganizationPayment = () => {
    trackExternal('onWebAppAttempOrganizationPayment', {});
};

export const onWebAppOrganizationPaymentError = ({
    error,
}: {
    error?: string;
}) => {
    trackExternal('onWebAppOrganizationPaymentError', { error });
};

export const onWebAppOrganizationPaymentSuccess = () => {
    trackExternal('onWebAppOrganizationPaymentSuccess', {});
};

export const onWebAppViewUpgradeToProModal = () => {
    trackExternal('onWebAppViewUpgradeToProModal', {});
};

export const onWebAppUpgradeToProModalClickCTA = () => {
    trackExternal('onWebAppUpgradeToProModalClickCTA', {});
};

export const OnWebAppChargeFinderLoad = ({
    variantName,
    experimentName,
}: {
    variantName: string;
    experimentName: string;
}) => {
    trackExternal('onWebAppChargeFinderLoad', {
        variant_name: variantName,
        experiment_name: experimentName,
    });
};

export const onWebAppBlogPostComment = ({
    code,
    name,
    comment,
}: {
    code?: string;
    name?: string;
    comment?: string;
}) => {
    trackExternal('onWebAppBlogPostComment', {
        code,
        name,
        comment,
    });
};

export const onMobileChargeFinderClickCTA = ({
    code,
    cta,
    variantName,
    experimentName,
}: {
    code?: string;
    cta?: string;
    variantName?: string;
    experimentName?: string;
}) => {
    trackExternal('onMobileChargeFinderClickCTA', {
        code,
        cta,
        variantName,
        experimentName,
    });
};

export const onWebAppSpreadsheetSelectOne = ({
    receiptId,
}: {
    receiptId: string;
}) => {
    trackExternal('onWebAppSpreadsheetSelectOne', {
        receipt_id: receiptId,
    });
};

export const onWebAppSpreadsheetDeselectOne = ({
    receiptId,
}: {
    receiptId: string;
}) => {
    trackExternal('onWebAppSpreadsheetDeselectOne', {
        receipt_id: receiptId,
    });
};

export const onWebAppSpreadsheetSelectAll = () => {
    trackExternal('onWebAppSpreadsheetSelectAll', {});
};

export const onWebAppSpreadsheetDeselectAll = () => {
    trackExternal('onWebAppSpreadsheetDeselectAll', {});
};

export const onWebAppSpreadsheetBulkDelete = (props: {
    count: number;
    source: string;
}) => {
    trackExternal('onWebAppSpreadsheetBulkDelete', props);
};

export const onWebAppPricingTableCTAClick = (props: {
    plan: string;
    platform: string;
}) => {
    trackExternal('onWebAppPricingTableCTAClick', props);
};

export const onWebAppClickExportDropdownOption = ({
    option,
    sharedReviewId,
}: {
    option: string;
    sharedReviewId: string;
}) => {
    trackExternal('onWebAppClickExportDropdownOption', {
        shared_review_id: sharedReviewId,
        option,
    });
};

export const onWebAppInboxToggleReceipt = (props: {
    status: 'checked' | 'unchecked';
}) => {
    trackExternal('onWebAppInboxToggleReceipt', props);
};

export const onWebAppInboxMultiCategorize = (props: {
    receiptsCount: number;
}) => {
    trackExternal('onWebAppInboxMultiCategorize', props);
};

export const onWebAppDeleteProcessingReceipt = ({
    receiptId,
    source,
}: {
    receiptId: string;
    source: string;
}) => {
    trackExternal('onWebAppDeleteProcessingReceipt', {
        source,
        receipt_id: receiptId,
    });
};

export const onWebAppDeleteReceipt = ({
    receiptId,
    source,
}: {
    receiptId: string;
    source: string;
}) => {
    trackExternal('onWebAppDeleteProcessingReceipt', {
        source,
        receipt_id: receiptId,
    });
};

export const onWebAppViewInbox = () => {
    trackExternal('onWebAppViewInbox', {});
};

export const onWebAppReconciliationConnectBank = () => {
    trackExternal('onWebAppReconciliationConnectBank', {});
};

export const onWebAppReconciliationCreateReceiptBulk = () => {
    trackExternal('onWebAppReconciliationCreateReceiptBulk', {});
};

export const onWebAppReconciliationCreateReceipt = () => {
    trackExternal('onWebAppReconciliationCreateReceipt', {});
};

export const onWebAppReconciliationPossibleMatchUsed = () => {
    trackExternal('onWebAppReconciliationPossibleMatchUsed', {});
};

export const onWebAppReconciliationTransactionsLoaded = () => {
    trackExternal('onWebAppReconciliationTransactionsLoaded', {});
};

export const onWebAppMileageTrackingLoad = () => {
    trackExternal('onWebAppMileageTrackingLoad', {});
};

export const onWebAppMileageTrackingSaveTrip = () => {
    trackExternal('onWebAppMileageTrackingSaveTrip', {});
};

export const onWebAppMileageTrackingSaveTripBulk = () => {
    trackExternal('onWebAppMileageTrackingSaveTripBulk', {});
};

export const onWebAppMoveFolder = (props: {
    itemId: string | number;
    sourceSpaceId: string | number;
    targetSpaceId: string | number;
}) => {
    trackExternal('onWebAppMoveFolder', props);
};

export const onWebAppGroupFolders = (props: {
    itemId: string | number;
    targetItemId: string | number;
    sourceSpaceId: string | number;
    targetSpaceId: string | number;
}) => {
    trackExternal('onWebAppGroupFolders', props);
};

export const onWebAppSendSharedFolderSMS = ({}) => {
    trackExternal('onWebAppSendSharedFolderSMS', {});
};

export const onWebAppSendSharedFolderInvite = ({}) => {
    trackExternal('onWebAppSendSharedFolderInvite', {});
};

export const onWebAppOrganizationSharedFolder = ({}) => {
    trackExternal('onWebAppOrganizationSharedFolder', {});
};

export const onWebAppLoadSupportPage = (
    props: EventPayload.onWebAppLoadSupportPageProps
) => {
    trackExternal('onWebAppLoadSupportPage', props);
};

export const onWebAppSubmitSupportForm = (
    props: EventPayload.onWebAppSubmitSupportFormProps
) => {
    trackExternal('onWebAppSubmitSupportForm', props);
};

export const onWebAppApplySearchFilter = (props: { input: string }) => {
    trackExternal('onWebAppApplySearchFilter', props);
};

export const onWebAppReverseSortOrder = (props: {
    columnKey: string;
    isAscending: boolean;
}) => {
    trackExternal('onWebAppReverseSortOrder', props);
};

export const onWebAppClearSortData = () => {
    trackExternal('onWebAppClearSortData', {});
};

export const onWebAppReconciliationFlowStart = () => {
    trackExternal('onWebAppReconciliationFlowStart', {});
};

export const onWebAppReconciliationFlowSelectGoals = (props: {
    goals: string[];
}) => {
    trackExternal('onWebAppReconciliationFlowSelectGoals', props);
};

export const onWebAppReconciliationFlowSelectIndustry = (props: {
    industry: string;
}) => {
    trackExternal('onWebAppReconciliationFlowSelectIndustry', props);
};

export const onWebAppReconciliationFlowConnectPlaid = () => {
    trackExternal('onWebAppReconciliationFlowConnectPlaid', {});
};

export const onWebAppReconciliationFlowConnectEmail = () => {
    trackExternal('onWebAppReconciliationFlowConnectEmail', {});
};

export const onWebAppReconciliationFlowRedirected = () => {
    trackExternal('onWebAppReconciliationFlowRedirected', {});
};

export const onWebAppAddLineItem = (props: {
    receipt_id: string;
    source: string;
}) => {
    trackExternal('onWebAppAddLineItem', props);
};

export const onWebAppDeleteLineItem = (props: {
    receipt_id: string;
    source: string;
}) => {
    trackExternal('onWebAppDeleteLineItem', props);
};

export const onWebAppPressLineItemSplit = (props: {
    line_item_count: number;
}) => {
    trackExternal('onWebAppPressLineItemSplit', props);
};

export const onWebAppSubmitSelectedFolders = (props: {
    action: string;
    folderCount: number;
}) => {
    trackExternal('onWebAppSubmitSelectedFolders', props);
};

export const onWebAppCheckLineItem = (props: {
    receipt_id: string;
    source: string;
}) => {
    trackExternal('onWebAppCheckLineItem', props);
};

export const onWebAppLandingTextingContinue = ({
    mobile,
    visitor_slug,
    source,
}: {
    mobile?: string;
    visitor_slug?: string;
    source: string;
}) => {
    trackExternal('onWebAppLandingTextingContinue', {
        source,
        mobile,
        visitor_slug,
    });
};

export const onWebAppCostEstimationStep = ({
    step,
    estimate_id,
    metadata,
}: {
    step: string;
    estimate_id: string;
    metadata?: any;
}) => {
    trackExternal('onWebAppCostEstimationStep', {
        step,
        estimate_id,
        metadata,
    });
};

export const onWebAppCostEstimationTakePicture = ({
    image_ids,
    exp_name,
}: {
    image_ids: string[];
    exp_name: string;
}) => {
    trackExternal('onWebAppCostEstimationTookPicture', {
        image_ids,
        exp_name,
    });
};

export const onWebAppCostEstimationSkipPicture = ({
    image_id,
}: {
    image_id: string;
}) => {
    trackExternal('onWebAppCostEstimationSkipPicture', {
        image_id,
    });
};

export const onWebAppCostEstimationEnterPrompt = ({
    prompt,
}: {
    prompt: string;
}) => {
    trackExternal('onWebAppCostEstimationEnterPrompt', {
        prompt,
    });
};

export const onWebAppCostEstimationUserType = ({
    userType,
}: {
    userType: string;
}) => {
    trackExternal('onWebAppCostEstimationUserType', {
        userType,
    });
};

export const onWebAppCostEstimationFurtherQuestionsShown = ({
    image_id,
    prompt,
    question_count,
}: {
    image_id: string;
    prompt: string;
    question_count: number;
}) => {
    trackExternal('onWebAppCostEstimationFurtherQuestionsShown', {
        image_id,
        prompt,
        question_count,
    });
};

export const onWebAppCostEstimationAnswerQuestion = ({
    question,
    answer,
    is_custom_answer,
}: {
    question: string;
    answer: string;
    is_custom_answer: boolean;
}) => {
    trackExternal('onWebAppCostEstimationAnswerQuestion', {
        question,
        answer,
        is_custom_answer,
    });
};

//TODO
export const onWebAppCostEstimationEditTrade = ({
    trade,
    estimate_id,
}: {
    trade: string;
    estimate_id: string;
}) => {
    trackExternal('onWebAppCostEstimationEditTrade', {
        trade,
        estimate_id,
    });
};

export const onWebAppCostEstimationAddTrade = ({
    trade,
    estimate_id,
}: {
    trade: string;
    estimate_id: string;
}) => {
    trackExternal('onWebAppCostEstimationAddTrade', {
        trade,
        estimate_id,
    });
};

export const onWebAppCostEstimationDeleteTrade = ({
    trade,
    estimate_id,
}: {
    trade: string;
    estimate_id: string;
}) => {
    trackExternal('onWebAppCostEstimationDeleteTrade', {
        trade,
        estimate_id,
    });
};

export const onWebAppCostEstimationEditLineItem = ({
    item,
}: {
    item: string;
}) => {
    trackExternal('onWebAppCostEstimationEditLineItem', {
        item,
    });
};

export const onWebAppCostEstimationRemoveLineItem = ({
    item,
}: {
    item: string;
}) => {
    trackExternal('onWebAppCostEstimationRemoveLineItem', {
        item,
    });
};

export const onWebAppCostEstimationRefine = ({
    image_id,
    instruction,
}: {
    image_id: string;
    instruction: string;
}) => {
    trackExternal('onWebAppCostEstimationRefine', {
        image_id,
        instruction,
    });
};

export const onWebAppCostEstimationAddLineItem = ({
    item,
}: {
    item: string;
}) => {
    trackExternal('onWebAppCostEstimationAddLineItem', {
        item,
    });
};

export const onWebAppCostEstimationComplete = ({
    image_id,
    prompt,
}: {
    image_id: string;
    prompt: string;
}) => {
    trackExternal('onWebAppCostEstimationComplete', {
        image_id,
        prompt,
    });
};

export const onWebAppCostEstimationAnswerFurtherQuestion = ({
    image_id,
    prompt,
    questions_left,
}: {
    image_id: string;
    prompt: string;
    questions_left: number;
}) => {
    trackExternal('onWebAppCostEstimationAnswerFurtherQuestion', {
        image_id,
        prompt,
        questions_left,
    });
};

export const onWebAppCostEstimationFeedback = ({
    feedback,
}: {
    feedback: string;
}) => {
    trackExternal('onWebAppCostEstimationFeedback', {
        feedback,
    });
};

export const onWebAppCostEstimationClickShare = () => {
    trackExternal('onWebAppCostEstimationClickShare', {});
};

export const onWebAppCostEstimationClickDownload = () => {
    trackExternal('onWebAppCostEstimationClickDownload', {});
};

export const onWebAppCostEstimationLoadExperiment = (props: {
    exp: string;
}) => {
    trackExternal('onWebAppCostEstimationLoadExperiment', props);
};

export const onWebAppCostEstimationLoadVariant = (props: {
    variant: string;
}) => {
    trackExternal('onWebAppCostEstimationLoadVariant', props);
};

export const onWebAppCostEstimationViewPaywall = () => {
    trackExternal('onWebAppCostEstimationViewPaywall', {});
};

export const onWebAppCostEstimationClickPayment = () => {
    trackExternal('onWebAppCostEstimationClickPayment', {});
};

export const onWebAppCostEstimationClickContinue = (token?: string) => {
    trackExternal('onWebAppCostEstimationClickContinue', { token });
};

export const onWebAppCodeCheckerClickDownload = () => {
    trackExternal('onWebAppCodeCheckerClickDownload', {});
};

export const onWebAppCodeCheckerTakePicture = ({
    image_id,
    exp_name,
}: {
    image_id: string;
    exp_name: string;
}) => {
    trackExternal('onWebAppCodeCheckerTakePicture', {
        image_id,
        exp_name,
    });
};

export const onWebAppLandingTextingCheckout = () => {
    trackExternal('onWebAppLandingTextingCheckout', {});
};

export const onWebAppTeamsAnnouncementClick = () => {
    trackExternal('onWebAppTeamsAnnouncementClick', {});
};

export const onWebAppTeamsAnnouncementContinue = () => {
    trackExternal('onWebAppTeamsAnnouncementContinue', {});
};

export const onWebAppPhotoAITakePicture = ({
    image_id,
    exp_name,
    is_camera,
    number_of_images,
}: {
    image_id: string;
    exp_name: string;
    is_camera?: boolean;
    number_of_images?: number;
}) => {
    trackExternal('onWebAppPhotoAITakePicture', {
        image_id,
        exp_name,
        is_camera,
        number_of_images,
    });
};

export const onWebAppPhotoAITagClick = ({
    tag,
    image_id,
}: {
    tag: string;
    image_id: string;
}) => {
    trackExternal('onWebAppPhotoAITagClick', {
        tag,
        image_id,
    });
};

export const onWebAppPhotoAIGotViewpoint = ({
    image_id,
    image_ids,
    viewpoint,
    tags,
    progress,
    title,
}: {
    image_id?: string;
    image_ids?: string[];
    viewpoint?: string;
    tags?: string[];
    progress?: string;
    title?: string;
}) => {
    trackExternal('onWebAppPhotoAIGotViewpoint', {
        image_id,
        image_ids,
        viewpoint,
        tags,
        progress,
        title,
    });
};

export const onWebAppPhotoAIGotGeolocation = ({
    geolocation,
}: {
    geolocation: string;
}) => {
    trackExternal('onWebAppPhotoAIGotGeolocation', {
        geolocation,
    });
};

export const onWebAppPhotoAIClickUploadAnotherPhoto = () => {
    trackExternal('onWebAppPhotoAIClickUploadAnotherPhoto', {});
};

export const onWebAppDisplayAvailableUpgrades = (props: {
    availableUpgrades: {
        tier: string;
        isRecommended: boolean;
    }[];
}) => {
    trackExternal('onWebAppDisplayAvailableUpgrades', props);
};

export const onWebAppSubscriptionShowDetails = (props: {
    tierName: string;
}) => {
    trackExternal('onWebAppSubscriptionShowDetails', props);
};

export const onWebAppCancelSubscription = (
    props: EventPayload.onWebAppCancelSubscriptionProps
) => {
    trackExternal('onWebAppCancelSubscription', { ...props });
};

export const onWebAppClickUpgradeBadge = (props: {
    source: string;
    upgradeTo: string;
    upgradeFrom?: string;
}) => {
    trackExternal('onWebAppClickUpgradeBadge', props);
};

export const onWebAppClickAddPaymentMethod = () => {
    trackExternal('onWebAppClickAddPaymentMethod');
};

export const onWebAppReconciliationNavigation = (props: { cohort: string }) => {
    trackExternal('onWebAppReconciliationNavigation', props);
};

export const onWebAppSetEmailSuccess = ({ email }: { email: string }) => {
    trackExternal('onWebAppSetEmailSuccess', {
        email,
    });
};

export const onWebAppNameSetSuccess = ({ name }: { name: string }) => {
    trackExternal('onWebAppNameSetSuccess', {
        name,
    });
};

export const onWebAppClickDrawerItem = (props: { item: string }) => {
    trackExternal('onWebAppClickDrawerItem', props);
};

export const onWebAppClickInboxRefresh = () => {
    trackExternal('onWebAppClickInboxRefresh');
};

export const onWebAppActivateFreemium = () => {
    trackExternal('onWebAppActivateFreemium', {});
};

export const onWebAppLoadReconciliationOnboarding = (props: {
    experiment: string;
}) => {
    trackExternal('onWebAppLoadReconciliationOnboarding', props);
};

export const onWebAppClickAppDownloadPopup = () => {
    trackExternal('onWebAppClickAppDownloadPopup', {});
};

export const onWebAppCloseAppDownloadPopup = () => {
    trackExternal('onWebAppCloseAppDownloadPopup', {});
};

export const onWebAppOnboardingNavigate = (props: { screen: string }) => {
    trackExternal('onWebAppOnboardingNavigate', props);
};

export const onWebAppLoadReconciliationScreen = () => {
    trackExternal('onWebAppLoadReconciliationScreen', {});
};

export const onWebAppTaxSavingsStart = () => {
    trackExternal('onWebAppTaxSavingsStart');
};

export const onWebAppTaxSavingsEnteredIndustry = (props: {
    industry: string;
}) => {
    trackExternal('onWebAppTaxSavingsEnteredIndustry', props);
};

export const onWebAppTaxSavingsGoogleClicked = () => {
    trackExternal('onWebAppTaxSavingsGoogleClicked');
};

export const onWebAppTaxSavingsGoogleConnected = () => {
    trackExternal('onWebAppTaxSavingsGoogleConnected');
};

export const onWebAppTaxSavingsImporting = () => {
    trackExternal('onWebAppTaxSavingsImporting');
};

export const onWebAppTaxSavingsChecking = () => {
    trackExternal('onWebAppTaxSavingsChecking');
};

export const onWebAppTaxSavingsDone = (props: { nb_receipts: number }) => {
    trackExternal('onWebAppTaxSavingsDone', props);
};

export const onWebAppTaxSavingsContinueToAccountSetup = () => {
    trackExternal('onWebAppTaxSavingsContinueToAccountSetup');
};

export const onWebAppVoiceAIStart = () => {
    trackExternal('onWebAppVoiceAIStart');
};

export const onWebAppVoiceAICompanyName = (props: { company_name: string }) => {
    trackExternal('onWebAppVoiceAICompanyName', props);
};

export const onWebAppVoiceAICompanyIndustry = (props: { industry: string }) => {
    trackExternal('onWebAppVoiceAICompanyIndustry', props);
};

export const onWebAppVoiceAICapabilities = (props: {
    capabilities: string[];
    provisioned_capabilities: string[];
}) => {
    trackExternal('onWebAppVoiceAICapabilities', props);
};

export const onWebAppVoiceAIChallenges = (props: {
    challenges: string[];
    provisioned_challenges: string[];
}) => {
    trackExternal('onWebAppVoiceAIChallenges', props);
};

export const onWebAppVoiceAILanguages = (props: { languages: string }) => {
    trackExternal('onWebAppVoiceAILanguages', props);
};

export const onWebAppVoiceAIConversationUpdated = (props: {
    transcript: string[];
}) => {
    trackExternal('onWebAppVoiceAIConversationUpdated', props);
};

export const onWebAppVoiceAILaunchClicked = () => {
    trackExternal('onWebAppVoiceAILaunchClicked', {});
};

export const onWebAppVoiceAIIntro = () => {
    trackExternal('onWebAppVoiceAIIntro');
};

export const onWebAppVoiceAIClickContinue = () => {
    trackExternal('onWebAppVoiceAIClickContinue');
};

export const onWebAppVoiceAISignUpComplete = () => {
    trackExternal('onWebAppVoiceAISignUpComplete');
};

export const onWebAppVoiceAIGreeting = (props: { greeting: string }) => {
    trackExternal('onWebAppVoiceAIGreeting', props);
};

export const onWebAppVoiceAIMicPermissionClick = () => {
    trackExternal('onWebAppVoiceAIMicPermissionClick');
};

export const onWebAppVoiceAIMicAccessDeclined = (props: {
    error?: string;
    message?: string;
}) => {
    trackExternal('onWebAppVoiceAIMicAccessDeclined', props);
};

export const onWebAppVoiceAIMicAccessGranted = () => {
    trackExternal('onWebAppVoiceAIMicAccessGranted');
};

export const onWebAppVoiceAIRecordingStarted = () => {
    trackExternal('onWebAppVoiceAIRecordingStarted');
};

export const onWebAppVoiceAIPaywallContinue = () => {
    trackExternal('onWebAppVoiceAIPaywallContinue');
};

export const onWebAppVoiceAIPaywallX = () => {
    trackExternal('onWebAppVoiceAIPaywallX');
};

export const onWebAppVoiceAIUseCase = (props: { use_case?: string }) => {
    trackExternal('onWebAppVoiceAIUseCase', props);
};

export const onWebAppVoiceAIIntention = (props: {
    intention: string;
    other?: string;
}) => {
    trackExternal('onWebAppVoiceAIIntention', props);
};

export const onWebAppVoiceAIUserEnableConditionalForwarding = (props: {
    carrier?: string;
}) => {
    trackExternal('onWebAppVoiceAIUserEnableConditionalForwarding', props);
};

export const onWebAppVoiceAIUserDisabledVoiceMail = () => {
    trackExternal('onWebAppVoiceAIUserDisabledVoiceMail');
};

export const onWebAppCallForwardingActivationFeeback = (props: {
    feedback: string;
}) => {
    trackExternal('onWebAppCallForwardingActivationFeeback', props);
};

export const onWebAppVoiceAIUserCallForwardingRetry = (props: {
    carrier?: string;
}) => {
    trackExternal('onWebAppVoiceAIUserCallForwardingRetry', props);
};

export const onWebAppVoiceAIRequestTestCall = () => {
    trackExternal('onWebAppVoiceAIRequestTestCall');
};

export const onWebAppVoiceAITestCallStatusFailed = (props: {
    carrier: string;
}) => {
    trackExternal('onWebAppVoiceAITestCallStatusFailed', props);
};

export const onWebAppVoiceAITestCallStatusSuccess = (props: {
    carrier: string;
}) => {
    trackExternal('onWebAppVoiceAITestCallStatusSuccess', props);
};

export const onWebAppVoiceAIContinueToAppClick = () => {
    trackExternal('onWebAppVoiceAIContinueToAppClick');
};

export const onWebAppVoiceAIFindCarrierByTwillio = (props: {
    carrier: string;
}) => {
    trackExternal('onWebAppVoiceAIFindCarrierByTwillio', props);
};

export const onWebAppVoiceAIFindCarrierByTwillioFailed = () => {
    trackExternal('onWebAppVoiceAIFindCarrierByTwillioFailed');
};

export const onWebAppEstimationPreviewSend = (
    props: EventPayload.onWebAppEstimationPreviewSendProps
) => {
    trackExternal('onWebAppEstimationPreviewSend', props);
};

export const onWebAppEstimationPreviewDownload = (
    props: EventPayload.onWebAppEstimationPreviewDownloadProps
) => {
    trackExternal('onWebAppEstimationPreviewDownload', props);
};

export const onWebAppEstimationPreviewApprove = (
    props: EventPayload.onWebAppEstimationPreviewApproveProps
) => {
    trackExternal('onWebAppEstimationPreviewApprove', props);
};

export const onWebAppEstimationPreviewEdit = (
    props: EventPayload.onWebAppEstimationPreviewEditProps
) => {
    trackExternal('onWebAppEstimationPreviewEdit', props);
};

export const onWebAppEstimationPreviewOpen = (
    props: EventPayload.onWebAppEstimationPreviewOpenProps
) => {
    trackExternal('onWebAppEstimationPreviewOpen', props);
};

export const onWebAppBidIQLoadCohort = ({ cohort }: { cohort: string }) => {
    trackExternal('onWebAppBidIQLoadCohort', { cohort });
};

export const onWebAppBidIQUploadImage = ({
    imageId,
}: {
    imageId: string | null;
}) => {
    trackExternal('onWebAppBidIQUploadImage', { imageId });
};

export const onWebAppBidIQEnterPostcode = ({
    postcode,
}: {
    postcode: string;
}) => {
    trackExternal('onWebAppBidIQEnterPostcode', { postcode });
};

export const onWebAppBidIQLoadResult = () => {
    trackExternal('onWebAppBidIQLoadResult', {});
};

export const onWebAppBidIQClickContinue = () => {
    trackExternal('onWebAppBidIQClickContinue', {});
};

export const onWebAppBidIQClickStartFreeTrial = () => {
    trackExternal('onWebAppBidIQClickStartFreeTrial', {});
};

export const onWebAppBidIQClickStartOver = () => {
    trackExternal('onWebAppBidIQClickStartOver', {});
};

export const onWebAppCostEstimationClickAddFolder = () => {
    trackExternal('onWebAppCostEstimationClickAddFolder');
};

export const onWebAppCostEstimationClickAddSpace = () => {
    trackExternal('onWebAppCostEstimationClickAddSpace');
};

export const onWebAppCostEstimationClickFolderEdit = () => {
    trackExternal('onWebAppCostEstimationClickFolderEdit');
};

export const onWebAppCostEstimationLoadHomeScreen = () => {
    trackExternal('onWebAppCostEstimationLoadHomeScreen');
};

export const onWebAppCostEstimationSaveToFolder = (props: {
    folder_id: string;
}) => {
    trackExternal('onWebAppCostEstimationSaveToFolder', props);
};

export const onWebAppCostEstimationDashboard = (props: { screen: string }) => {
    trackExternal('onWebAppCostEstimationDashboard', props);
};

export const onWebAppLoginSelectApp = (props: { app: string }) => {
    trackExternal('onWebAppLoginSelectApp', props);
};

export const onWebAppCostEstimationDeleteEstimate = (props: {
    estimates: Array<{
        estimate_id: string;
        estimate_name: string;
    }>;
}) => {
    trackExternal('onWebAppCostEstimationDeleteEstimate', props);
};

export const onWebAppCostEstimationProjectDetailsClick = () => {
    trackExternal('onWebAppCostEstimationProjectDetailsClick');
};

export const onWebAppCostEstimationProjectDetailsPanel = (props: {
    selected_estimate: {
        estimate_id: string;
        estimate_name: string;
    };
}) => {
    trackExternal('onWebAppCostEstimationProjectDetailsPanel', props);
};

export const onWebAppCostEstimationBookingLinkStep = ({
    step,
    form_id,
    metadata,
}: {
    step: string;
    form_id: number;
    metadata?: any;
}) => {
    trackExternal('onWebAppCostEstimationBookingLinkStep', {
        step,
        form_id,
        metadata,
    });
};

export const onWebAppCostEstimationBookingLinkSkipTakePhoto = ({
    form_id,
}: {
    form_id: number;
}) => {
    trackExternal('onWebAppCostEstimationBookingLinkSkipTakePhoto', {
        form_id,
    });
};
